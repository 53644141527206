import axios, { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useState } from 'react';

function useFetch<T>(url: string, options: AxiosRequestConfig<any> | undefined = undefined, skip = false) {
	const [data, setData] = useState<T>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>();

	const fetchData = useCallback(async () => {
		if (skip) return;

		setLoading(true);
		const source = axios.CancelToken.source();

		try {
			const res = await axios(url, { cancelToken: source.token, ...options });
			if (res.data) {
				setData(res.data);
			}
		} catch (err: any) {
			if (!axios.isCancel(err)) {
				setError(err?.response?.data || err.message);
			}
		} finally {
			setLoading(false);
		}

		// Return the cancel function to be called on unmount or on change of dependencies
		return () => {
			source.cancel();
		};

		// eslint-disable-next-line
	}, [url, skip]);

	useEffect(() => {
		if (!skip) {
			const cancelFetch = fetchData();
			return () => {
				if (cancelFetch instanceof Function) {
					cancelFetch();
				}
			};
		}
	}, [fetchData, skip]);

	const clearData = () => {
		setData(undefined);
	};

	const fetchAgain = async () => {
		return await fetchData();
	};

	return { data, loading, error, fetchAgain, clearData };
}

export default useFetch;
