import { BooleanInput, Create, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

const MediaCategoryCreate = () => {
  return (
    <Create resource='vod/category'>
      <SimpleForm>
        <TextInput source='hebrew_name' />
        <TextInput source='english_name' />
        <BooleanInput source='visible' />
        <NumberInput source='priority' label='עדיפות' />
        <TextInput source='type' defaultValue={'video'} />
        <ReferenceInput source='parent_category' reference='vod/categories/all' resource='vod/category'>
          <SelectInput optionText={'hebrew_name'} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default MediaCategoryCreate;
