import { Button, Grid, Input, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
	ArrayField,
	BooleanInput,
	Datagrid,
	Edit,
	FormDataConsumer,
	FormTab,
	FunctionField,
	ImageField,
	ImageInput,
	NumberInput,
	ReferenceArrayInput,
	SaveButton,
	SelectArrayInput,
	SelectInput,
	TabbedForm,
	TextField,
	TextInput,
	Toolbar,
	useEditController,
	useGetRecordId,
	useNotify,
} from 'react-admin';
import { MdDelete } from 'react-icons/md';
import constants from '../../constants';
import PrivateServicesTable from './PrivateServicesTable';

export const ExpertEdit = () => {
	const [newRecommendation, setNewRecommendation] = useState({ name: '', text: '', language: 'he' });
	const [privateServices, setPrivateServices] = useState<any[]>();
	const notify = useNotify();
	const current_instructor = useEditController();
	const instructor_id = useGetRecordId();
	const getPrivateServices = useCallback(async () => {
		try {
			const response = await axios.get(`${constants.API_URL}/instructor/private_services/all`, {
				params: { instructor_id },
			});
			console.log(response.data);
			setPrivateServices(response.data);
		} catch (error) {}
	}, [setPrivateServices, instructor_id]);

	useEffect(() => {
		getPrivateServices();
	}, [getPrivateServices, instructor_id]);

	const refreshFunction = async (message?: string) => {
		await current_instructor.refetch();
		await getPrivateServices();
		notify(message || 'בוצע', { type: 'success' });
	};

	return (
		<Edit
			mutationMode='pessimistic'
			mutationOptions={{ onError: (e: any, _: any) => notify(e.response.data.message, { type: 'error' }) }}
			title={current_instructor.record ? `${current_instructor.record?.name || ''}\t\t|\t\t${current_instructor.record?._id || ''}` : '...'}>
			<TabbedForm
				toolbar={
					<Toolbar>
						<SaveButton />
					</Toolbar>
				}>
				<FormTab label={'info'}>
					<Grid container spacing={2}>
						<Grid item sm={4}>
							<FunctionField
								render={(record: any) => (
									<Typography variant='subtitle1' textAlign={'right'}>
										ID: {record.id}
									</Typography>
								)}
							/>
						</Grid>

						<Box width={'100%'} />
						<Grid item sm={4}>
							<TextInput source='name' fullWidth />
						</Grid>
						<Grid item sm={4}>
							<TextInput source='english_name' fullWidth />
						</Grid>
						<Box width='100%' />
						<Grid item sm={4}>
							<ReferenceArrayInput reference='instructor-category/all' source='category' perPage={9999}>
								<SelectArrayInput
									fullWidth
									optionText={(record) => `${record.__t === 'helping_hand' ? 'HH' : ''} ${record.hebrew_name}`}
									optionValue='id'
									style={{ marginTop: -1 }}
								/>
							</ReferenceArrayInput>
						</Grid>
						<Box width='100%' />
						<Grid item sm={8}>
							<TextInput source='title' fullWidth />
						</Grid>
						<Grid item sm={8}>
							<TextInput source='english_title' fullWidth />
						</Grid>
						<Box width='100%' />
						<Grid item sm={3}>
							<TextInput source='short_title.he' fullWidth inputProps={{}} />
						</Grid>
						<Grid item sm={3}>
							<TextInput source='short_title.en' fullWidth inputProps={{}} />
						</Grid>
						<Box width='100%' />
						<Grid item sm={8}>
							<TextInput source='description_lng.he' multiline fullWidth label={'תיאור בעברית'} />
						</Grid>
						<Grid item sm={8}>
							<TextInput source='description_lng.en' multiline fullWidth label={'תיאור באנגלית'} dir='ltr' />
						</Grid>
						<Box width='100%' />
						<Grid item sm={3}>
							<TextInput source='location' multiline fullWidth label={'מיקום קליניקה'} dir='rtl' />
						</Grid>
						<Grid item sm={3}>
							<TextInput
								source='languages'
								multiline
								fullWidth
								label={'שפות (מופרד ב,)'}
								dir='rtl'
								format={(value: string[]) => value?.join(',')}
								parse={(value: string) => value?.split(',')}
							/>
						</Grid>
						<Grid item sm={3}>
							<BooleanInput source='is_online' fullWidth label={'מקבל אונליין'} dir='ltr' defaultChecked />
						</Grid>
						<Grid item sm={3}>
							<BooleanInput source='is_psychologist' fullWidth label='פסיכולוג/ית' dir='ltr' />
						</Grid>
						<Grid item sm={3}>
							<BooleanInput source='licensed_dealer' fullWidth label='עוסק מורשה' dir='ltr' />
						</Grid>

						<Box width='100%' />
						<Grid item sm={8}>
							<TextInput source='image' fullWidth />
							<ImageField source='image' />
						</Grid>
						<Grid item sm={8}>
							<FunctionField
								render={(record: any) => (
									<ImageInput source='image'>
										<ImageField source={'src'} />
									</ImageInput>
								)}
							/>
						</Grid>
						<Box width={'100%'} />
						<Grid item sm={3}>
							<SelectInput
								fullWidth
								source='anchorImage'
								style={{ marginTop: 8 }}
								choices={[
									{ id: 'top', name: 'top' },
									{ id: 'center', name: 'center' },
									{ id: 'bottom', name: 'bottom' },
								]}
							/>
						</Grid>
						<Box width='100%' />
						<Grid item sm={3}>
							<TextInput source='email' fullWidth />
						</Grid>
						<Grid item sm={3}>
							<TextInput source='phone' fullWidth />
						</Grid>
						<Box width='100%' />
						<Grid item sm={3}>
							<TextInput source='button_text.he' label='טקסט כפתור (עב)' fullWidth />
						</Grid>
						<Grid item sm={3}>
							<TextInput source='button_text.en' label='טקסט כפתור (אנ)' fullWidth />
						</Grid>
						<Box width='100%' />
						<Grid item sm={6}>
							<TextInput source='zoom' fullWidth />
						</Grid>
						<Grid item sm={2}>
							<TextInput source='zoom_password' fullWidth />
						</Grid>
						<Grid item sm={6}>
							<TextInput source='url' fullWidth />
						</Grid>
						<Box width={'100%'} />

						<Grid item sm={6}>
							<TextInput source='helping_hand_url' fullWidth />
						</Grid>

						<Box width={'100%'} />
						<Grid item xs={2}>
							<BooleanInput source='safe_space' label='המרחב הבטוח' />
						</Grid>
						<FormDataConsumer>
							{({ formData }) =>
								formData.safe_space && (
									<>
										<Grid item xs={2}>
											<BooleanInput source='safe_space_filter_trauma' label='המרחב הבטוח - חרדה וטראומה' />
										</Grid>
										<Grid item xs={2}>
											<BooleanInput source='safe_space_filter_children_therapy_3_10' label='המרחב הבטוח - 3-10' />
										</Grid>
										<Grid item xs={2}>
											<BooleanInput source='safe_space_filter_children_therapy_10_18' label='המרחב הבטוח - 10-18' />
										</Grid>
									</>
								)
							}
						</FormDataConsumer>
						<Grid item xs={2}>
							<BooleanInput source='showInSubscription' label='מופיע/ה במנוי' />
						</Grid>
						<Grid item xs={2}>
							<ReferenceArrayInput source='showForCompany' label='מופיע/ה לחברה' reference='company' perPage={100} sort={{ field: 'english_name', order: 'ASC' }}>
								<SelectArrayInput optionText='english_name' />
							</ReferenceArrayInput>
						</Grid>
						<Grid item xs={2}>
							<BooleanInput source='active' label='פעיל/ה' />
						</Grid>
						<Grid item xs={2}>
							<NumberInput source='priority' label='עדיפות' />
						</Grid>
					</Grid>
				</FormTab>
				<FormTab label='Recommendations'>
					<Grid container>
						<Grid item sm={10}>
							<ArrayField source='recomendations'>
								<Datagrid bulkActionButtons={false}>
									<TextField textAlign='right' source='name' />
									<TextField textAlign='right' source='text' />
									<TextField source='language' />
									<FunctionField
										render={(record: any) => (
											<Button
												onClick={async () => {
													await axios.delete(`${constants.API_URL}instructor/recommendation`, {
														data: { instructor_id: current_instructor?.record?.id, recommendation_id: record._id },
													});
													current_instructor.refetch();
												}}>
												<MdDelete />
											</Button>
										)}
									/>
								</Datagrid>
							</ArrayField>
						</Grid>
						<Box width='100%' mt={2} />
						<Grid item sm={2}>
							<Input placeholder='שם' value={newRecommendation.name} onChange={(e) => setNewRecommendation((prev) => ({ ...prev, name: e.target.value }))} />
						</Grid>
						<Grid item sm={6}>
							<Input
								multiline
								placeholder='טקסט המלצה'
								fullWidth
								value={newRecommendation.text}
								onChange={(e) => setNewRecommendation((prev) => ({ ...prev, text: e.target.value }))}
							/>
						</Grid>
						<Grid item sm={2}>
							<Select
								multiline
								placeholder='שפה'
								fullWidth
								style={{ height: 30 }}
								value={newRecommendation.language}
								onChange={(e) => setNewRecommendation((prev) => ({ ...prev, language: e.target.value }))}>
								<MenuItem value={'he'}>עברית</MenuItem>
								<MenuItem value={'en'}>אנגלית</MenuItem>
							</Select>
						</Grid>
						<Grid item sm={2}>
							<FunctionField
								render={(record: any) => (
									<Button
										onClick={async () => {
											await axios.post(`${constants.API_URL}instructor/recommendation`, {
												instructor_id: record.id,
												...newRecommendation,
											});

											setNewRecommendation({ name: '', text: '', language: 'he' });
											current_instructor.refetch();
										}}>
										{'הוספת המלצה'}
									</Button>
								)}
							/>
						</Grid>
					</Grid>
				</FormTab>
				<FormTab label='Private Services'>
					<Stack spacing={2} width={'100%'}>
						<PrivateServicesTable
							title='שירותים במנוי'
							data={privateServices?.filter((x: any) => !x.public)}
							refreshFunc={refreshFunction}
							current_instructor={current_instructor}
						/>
						<PrivateServicesTable
							title='שירותים מחוץ למנוי'
							data={privateServices?.filter((x: any) => x.public)}
							refreshFunc={refreshFunction}
							current_instructor={current_instructor}
						/>

						<PrivateServicesTable title='יצירת שירות חדש' data={[]} refreshFunc={refreshFunction} current_instructor={current_instructor} showCreate />
					</Stack>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};

export default ExpertEdit;
