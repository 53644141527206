import axios from 'axios';
import constants from '../constants';
import { ROLE } from './auth.types';
import { AuthProvider } from 'react-admin';

const authorizedRoles: ROLE[] = ['admin', 'superadmin', 'business-admin', 'editor', 'group-sessions-viewer'];
// in src/authProvider.js
const authProvider: AuthProvider = {
	// called when the user attempts to log in
	login: async ({ username, password }: { username: string; password: string }) => {
		const response = await axios.post(`${constants.API_URL}/user/login`, {
			email: username,
			password,
			platform: 'admin',
		});

		const resJson = response.data;

		if (!resJson.member.role || authorizedRoles.indexOf(resJson.member.role) === -1) return Promise.reject();
		if (resJson.token) {
			localStorage.setItem('token', resJson?.token);
			localStorage.setItem('token_exp', (new Date().getTime() + 4 * 60 * 1000).toString());
			localStorage.setItem('refresh_token', resJson?.member.refresh_token);
			localStorage.setItem('role', resJson?.member?.role);
			localStorage.setItem('company', resJson?.member?.company?._id);
			return Promise.resolve();
		}
		return Promise.reject();
	},
	// called when the user clicks on the logout button
	logout: () => {
		localStorage.removeItem('token');
		return Promise.resolve();
	},
	// called when the API returns an error
	checkError: ({ status }) => {
		// if (status === 401 || status === 403) {
		//   localStorage.removeItem('username');
		//   return Promise.reject();
		// }
		return Promise.resolve();
	},
	// called when the user navigates to a new location, to check for authentication
	checkAuth: async () => {
		const token = localStorage.getItem('token');
		const token_exp = localStorage.getItem('token_exp');

		try {
			if (!token) return Promise.reject();
			if (token && token_exp && new Date(Number.parseInt(token_exp)) > new Date()) {
				return Promise.resolve();
			}

			const refresh_token = localStorage.getItem('refresh_token');
			const refresh_response = await axios.post(constants.API_URL + 'user/refresh', {
				refresh_token,
			});

			if (refresh_response.status === 201) {
				// 1) put token to LocalStorage
				localStorage.setItem('token', refresh_response.data);
				localStorage.setItem('token_exp', (new Date().getTime() + 4 * 60 * 1000).toString());
				// 2) Change Authorization header
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + refresh_response.data;
				return Promise.resolve();
			}
		} catch (err) {
			return Promise.reject();
		}

		return Promise.reject();
	},
	// called when the user navigates to a new location, to check for permissions / roles
	getPermissions: () => {
		const role = localStorage.getItem('role');

		return role ? Promise.resolve(role) : Promise.reject();
	},
};

export default authProvider;
