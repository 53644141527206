import {
	Box,
	CircularProgress,
	Grid,
	Input,
	List,
	ListItem,
	Button as MuiButton,
	TextField as MuiTextField,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { useEffect, useReducer, useState } from 'react';
import {
	BooleanField,
	Button,
	ChipField,
	DateField,
	EditButton,
	FunctionField,
	Labeled,
	NumberField,
	ReferenceArrayField,
	ResourceProps,
	Show,
	SingleFieldList,
	Tab,
	TabbedShowLayout,
	TextField,
	Toolbar,
	useList,
	useNotify,
	useShowController,
} from 'react-admin';

import axios, { AxiosError } from 'axios';

import moment from 'moment';
import { FaCheck, FaX } from 'react-icons/fa6';
import constants from '../../constants';
import { httpClient } from '../../providers/myDataProvider';
import { getLocalizedErrorMessage } from '../../utils/functions';

const renderType = (type: string) => {
	if (!type) return '';
	else if (type === 'trial') return 'התנסות';
	else if (type?.includes('newborn')) return 'מנוי לידה';
	return type;
};
const SendNotificationView = (props: { default_message: string; meeting_id: string }) => {
	const [notificationBody, setNotificationBody] = useState(props.default_message);
	const [sendNotificationResponse, setSendNotificationResponse] = useState<string>();
	const sendNotification = async () => {
		try {
			const response = await httpClient(`${constants.API_URL}/meeting/${props.meeting_id}/send_notification`, {
				method: 'POST',
				body: JSON.stringify({ body: notificationBody }),
			});
			setSendNotificationResponse(response.status === 200 ? 'SENT' : 'ERROR');
		} catch (error) {
			setSendNotificationResponse('ERROR');
		}
	};

	return (
		<div className='container' style={{ display: 'flex', width: '100%', marginBlockStart: 20, alignSelf: 'center', alignItems: 'flex-start' }}>
			<div className='container' style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBlockStart: 20 }}>
				<Input
					onChange={(e) => {
						setNotificationBody(e.target.value);
					}}
					value={notificationBody}
				/>
				<Button onClick={sendNotification} label='שלח נוטיפיקציה' />
			</div>
			<Typography variant='caption'>{sendNotificationResponse}</Typography>
		</div>
	);
};
const MeetingShow: ResourceProps['show'] = () => {
	const { record, refetch, isFetching } = useShowController();

	const [members, setMembers] = useState<{ members: any[]; guests: any[] }>({ members: [], guests: [] });
	const listContext = useList({ data: members.members });
	const [inviteEmail, setInviteEmail] = useState('');
	const notify = useNotify();
	useEffect(() => {
		const getMembers = async () => {
			const response = await httpClient(`${constants.API_URL}/meeting/${record.id}/members`);
			setMembers(response.json);
		};

		if (!isFetching) {
			getMembers();
		}
	}, [record, isFetching]);

	const [loading, setLoading] = useState(false);
	enum ActionType {
		CHANGE = 'CHANGE',
	}
	type NotificationFields = {
		title: { he: string; en: string };
		body: { he: string; en: string };
	};

	const [state, dispatch] = useReducer(
		(state: NotificationFields, action: { type: ActionType; field: keyof NotificationFields; language: 'he' | 'en'; payload: string }) => {
			return { ...state, [action.field]: { ...state[action.field], [action.language]: action.payload } };
		},
		{
			title: { he: 'הערב ב-GroupHug', en: 'Coming up tonight' },
			body: { he: record?.title, en: record?.english_title },
		}
	);

	return (
		<Show
			emptyWhileLoading
			resource='meeting/flat'
			actions={
				<Toolbar>
					<EditButton resource='meeting' />
				</Toolbar>
			}>
			<TabbedShowLayout>
				<Tab label={'פרטים'}>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={3}>
							<Labeled fullWidth>
								<DateField source='time' locales='he-IL' options={{ hourCycle: 'h24', dateStyle: 'short', timeStyle: 'short' }} showTime label='מועד' />
							</Labeled>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Labeled fullWidth>
								<TextField source='title' label='כותרת' />
							</Labeled>
						</Grid>
						<Grid item xs={12} lg={1}>
							<Labeled fullWidth fontWeight={'bold'}>
								<NumberField source='registered' label='רשומים' />
							</Labeled>
						</Grid>
						<Grid item xs={12} lg={1}>
							<Labeled textAlign='center' fullWidth>
								<BooleanField justifyContent={'center'} source='should_record' textAlign='center' label='צריך להקליט' />
							</Labeled>
						</Grid>
						<Grid item xs={12} lg={1}>
							<Labeled textAlign='center' fullWidth>
								<BooleanField justifyContent={'center'} source='extra.zoom_test_required' textAlign='center' label='טסט' />
							</Labeled>
						</Grid>
						<Grid item xs={12} lg={1}>
							<Labeled textAlign='center' fullWidth>
								<BooleanField justifyContent={'center'} source='extra.zoom_join_required' textAlign='center' label='לעלות למפגש' />
							</Labeled>
						</Grid>
						<Box width='100%' />
						<Grid item xs={12} lg={6}>
							<Labeled fullWidth>
								<TextField source='zoom' emptyText='-' />
							</Labeled>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Labeled fullWidth>
								<TextField source='zoom_password' emptyText='-' />
							</Labeled>
						</Grid>
						<Box width='100%' pb={2} />
						<Grid item xs={12} lg={4}>
							<Labeled fullWidth textAlign={'start'}>
								<ReferenceArrayField reference='vod/categories' source='extra.vod_category' label='להעלות הקלטה לקטגוריות'>
									<SingleFieldList>
										<ChipField source='hebrew_name' />
									</SingleFieldList>
								</ReferenceArrayField>
							</Labeled>
						</Grid>
						<Box width='100%' pb={2} />
						<Grid item xs={12} lg={6}>
							<Labeled textAlign='start' fullWidth fontSize={24} color={'black'}>
								<TextField source='extra.text' textAlign='center' label='הערות' emptyText='-' />
							</Labeled>
						</Grid>
					</Grid>
					{members ? (
						<div>
							<Typography variant='h4'>רשומים</Typography>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Table size='small' style={{ width: 500, alignSelf: 'center' }}>
									<TableHead>
										<TableRow>
											<TableCell></TableCell>
											<TableCell>מנויים</TableCell>
											<TableCell>אורחים</TableCell>
											<TableCell>סהכ</TableCell>
										</TableRow>
									</TableHead>
									<TableRow>
										<TableCell>נרשמו</TableCell>
										<TableCell>{members.members.length}</TableCell>
										<TableCell>{members.guests.length}</TableCell>
										<TableCell>{members.guests.length + members.members.length}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>אישרו</TableCell>
										<TableCell>{members.members.filter((x) => x.rsvp_status === 'approve').length}</TableCell>
										<TableCell>{members.guests.filter((x) => x.rsvp_status === 'approve').length}</TableCell>
										<TableCell>{members.members.filter((x) => x.rsvp_status === 'approve').length + members.guests.filter((x) => x.rsvp_status === 'approve').length}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>לא יגיעו</TableCell>
										<TableCell>{members.members.filter((x) => x.rsvp_status === 'cancel').length}</TableCell>
										<TableCell>{members.guests.filter((x) => x.rsvp_status === 'cancel').length}</TableCell>
										<TableCell>{members.members.filter((x) => x.rsvp_status === 'cancel').length + members.guests.filter((x) => x.rsvp_status === 'cancel').length}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>נכנסו</TableCell>
										<TableCell>{members.members.filter((x) => x.status === 'join').length}</TableCell>
										<TableCell>{members.guests.filter((x) => x.status === 'join').length}</TableCell>
										<TableCell>{members.members.filter((x) => x.status === 'join').length + members.guests.filter((x) => x.status === 'join').length}</TableCell>
									</TableRow>

									<TableRow>
										<TableCell>משובים</TableCell>
										<TableCell>{members.members.filter((x) => x.reviewed).length}</TableCell>
										<TableCell>{members.guests.filter((x) => x.reviewed).length}</TableCell>
										<TableCell>{members.members.filter((x) => x.reviewed).length + members.guests.filter((x) => x.reviewed).length}</TableCell>
									</TableRow>
								</Table>
							</div>
						</div>
					) : null}
					<Typography variant='h6'>משתמשים</Typography>
					<Button
						onClick={() => {
							navigator.clipboard.writeText(
								members.members
									.concat(members.guests)
									.map((e: any) => e.email)
									.join(', ')
							);
						}}>
						<Typography variant='body2' style={{ fontSize: 12 }}>
							העתק מיילים
						</Typography>
					</Button>
					{listContext.data && (
						<TableContainer sx={{ maxHeight: 600 }}>
							<Table size='small' stickyHeader style={{}}>
								<TableHead style={{ textAlign: 'right' }}>
									<TableRow style={{ textAlign: 'right' }}>
										<TableCell width={100}>מועד הרשמה</TableCell>
										<TableCell width={140}>מייל</TableCell>
										<TableCell width={80} />
										<TableCell width={80} />
										<TableCell width={80} />
										<TableCell width={100}>סטטוס אישור הגעה</TableCell>
										<TableCell width={100}>התחבר/ה למפגש</TableCell>
										<TableCell width={100}>נכתב משוב</TableCell>
									</TableRow>
								</TableHead>

								{listContext.data
									.filter((x) => x.active)
									.sort((a, b) => (a.status < b.status ? 1 : -1))
									.map((member: any) => (
										<TableRow>
											<TableCell>{moment(member.createdAt).format('DD/MM HH:mm')}</TableCell>
											<TableCell>{member.email}</TableCell>
											<TableCell>{member.first_name}</TableCell>
											<TableCell>{member.last_name}</TableCell>
											<TableCell>{renderType(member.type)}</TableCell>
											<TableCell style={{ textAlign: 'center' }}>
												{member.rsvp_status === 'approve' ? <FaCheck color='green' /> : member.rsvp_status === 'cancel' ? <FaX color='red' /> : null}
											</TableCell>
											<TableCell style={{ textAlign: 'center' }}>{member.status === 'join' ? <FaCheck color='green' /> : null}</TableCell>
											<TableCell style={{ textAlign: 'center' }}>{member.reviewed ? <FaCheck color='green' /> : null}</TableCell>
										</TableRow>
									))}
							</Table>
						</TableContainer>
					)}
					<Typography variant='h6'>אורחים</Typography>
					{members.guests && (
						<List style={{ textAlign: 'left', direction: 'ltr' }}>
							{members.guests.map((guest) => (
								<ListItem>{guest.email}</ListItem>
							))}
						</List>
					)}
					{/* <Typography variant='h4'>תזכורות</Typography> */}
					{/* <ArrayField source='notifications' textAlign='right' label=''>
            <Datagrid bulkActionButtons={false} dir='rtl'>
              <DateField
                source='time'
                textAlign='right'
                label='זמן'
                showTime
                options={{ timeStyle: 'short', dateStyle: 'short' }}
              />
              <TextField source='type' label='סוג' textAlign='right' />
              <TextField source='template' label='לפי תבנית' textAlign='right' />

              <TextField source='success_time' label='זמן ביצוע' textAlign='right' />
            </Datagrid>
          </ArrayField> */}
					<FunctionField render={(record: any) => <SendNotificationView default_message={record?.title} meeting_id={record._id} />} />
				</Tab>
				<Tab label='שליחת PUSH'>
					<Box>
						<Grid container spacing={2}>
							<Grid item sm={3}>
								<div dir='rtl'>
									<MuiTextField
										fullWidth
										required
										label='כותרת בעברית'
										value={state?.title.he}
										placeholder='כותרת עברית'
										onChange={(e) => dispatch({ type: ActionType.CHANGE, field: 'title', language: 'he', payload: e.target.value })}
									/>
								</div>
							</Grid>
							<Grid item sm={3}>
								<MuiTextField
									fullWidth
									dir='rtl'
									InputProps={{ dir: 'rtl' }}
									value={state.title.en}
									label='כותרת אנגלית'
									required
									onChange={(e) => dispatch({ type: ActionType.CHANGE, field: 'title', language: 'en', payload: e.target.value })}
								/>
							</Grid>
							<Box width='100%' />
							<Grid item sm={6}>
								<MuiTextField
									fullWidth
									required
									label='טקסט בעברית'
									value={state.body.he}
									placeholder='כותרת עברית'
									onChange={(e) => dispatch({ type: ActionType.CHANGE, field: 'body', language: 'he', payload: e.target.value })}
								/>
							</Grid>
							<Grid item sm={6}>
								<MuiTextField
									fullWidth
									dir='rtl'
									required
									InputProps={{ dir: 'rtl' }}
									value={state.body.en}
									label='טקסט אנגלית'
									onChange={(e) => dispatch({ type: ActionType.CHANGE, field: 'body', language: 'en', payload: e.target.value })}
								/>
							</Grid>
						</Grid>
						{loading ? (
							<CircularProgress size={18} />
						) : (
							<MuiButton
								disabled={record?.push_sent || !(state.body.he && state.body.en && state.title.he && state.title.en)}
								onClick={async () => {
									try {
										setLoading(true);
										await axios.post(`${constants.API_URL}/meeting/${record.id}/send_push`, state);
										refetch();
										notify('נוטיפיקציה נשלחה למשתמשים', { type: 'success' });
										refetch();
									} catch (error) {
										notify('שגיאה', { type: 'error' });
									} finally {
										setLoading(false);
									}
								}}>
								שליחת למשתמשים
							</MuiButton>
						)}
					</Box>
				</Tab>
				<Tab label='הוספת נרשמים'>
					<Grid container spacing={2}>
						<Grid item sm={4}>
							<MuiTextField
								fullWidth
								label='מייל'
								value={inviteEmail}
								onChange={(e) => {
									setInviteEmail(e.target.value);
								}}
							/>
						</Grid>
						<Grid item sm={2} alignSelf={'center'}>
							<MuiButton
								onClick={async () => {
									try {
										await axios.post(`${constants.API_URL}/appointment/email`, {
											email: inviteEmail,
											meeting_id: record._id,
										});
										notify(`רשמנו את ${inviteEmail} למפגש`);
									} catch (error) {
										notify(getLocalizedErrorMessage(error as AxiosError) || `לא הצלחנו`, { type: 'error' });
									}
								}}>
								הרשמה
							</MuiButton>
						</Grid>
					</Grid>
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};
export default MeetingShow;
