import { Button, Checkbox, Input, List, ListItem, ListItemButton, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DeleteButton, EditButton, ReferenceField, Show, ShowButton, Tab, TabbedShowLayout, TextField, usePermissions, useRecordContext, useShowController } from 'react-admin';
import constants from '../../constants';
import MemberProactivePlanForm from '../Inputs/MemberProactivePlanForm';

const SendMemberMessages = () => {
	const [message, setMessage] = useState('');
	const [from, setFrom] = useState('GroupHug');
	const [title, setTitle] = useState('');
	const [isSendNotification, setIsSendNotification] = useState(false);
	const record = useRecordContext();
	const handleSendMessageToUser = async () => {
		try {
			await axios.post(`${constants.API_URL}member/${record.id}/message`, {
				text: message,
				notification: isSendNotification,
				from,
				title,
			});
			setMessage('');
		} catch (error) {}
	};
	return (
		<div style={{ width: 400, display: 'flex', flexDirection: 'column' }}>
			<Input placeholder='כותרת' value={title} onChange={(e) => setTitle(e.target.value)} />
			<Input style={{ marginBlock: 10 }} multiline placeholder='הודעה זאת תופיע למשתמש בכניסה לאפליקיה במסך inbox' value={message} onChange={(e) => setMessage(e.target.value)} />
			<Typography textAlign={'right'}>{`מאת`}</Typography>
			<Input placeholder='מאת' value={from} onChange={(e) => setFrom(e.target.value)} />
			<Button style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', marginTop: 20 }} name='Send User Message' onClick={handleSendMessageToUser}>
				<Typography>{`Send Message To`}</Typography>
				<Typography>{`${record?.first_name}`}</Typography>
			</Button>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Checkbox value={isSendNotification} onChange={(e) => setIsSendNotification(e.target.checked)} title='כולל נוטיפיקציה' />
				<Typography>כולל נוטיפיקציה</Typography>
			</div>
		</div>
	);
};
const MemberShow = (props: any) => {
	const { record } = useShowController();
	const [meetings, setMeetings] = useState<any[]>([]);

	useEffect(() => {
		const fetchMemberMeetings = async () => {
			try {
				const response = await axios.get(`${constants.API_URL}member/${record.id}/meetings`);
				setMeetings(response.data);
			} catch (error) {}
		};
		fetchMemberMeetings();
	}, [record]);
	const { permissions } = usePermissions();
	const logoutUser = async () => {
		try {
			await axios.get(`${constants.API_URL}user/${record.id}/logout`);
		} catch (error) {}
	};
	const isSuperAdmin = permissions === 'superadmin';
	return (
		<Show
			actions={
				<Stack py={1} direction={'row'} display={'flex'} justifyContent={'flex-end'}>
					<ShowButton />
					{isSuperAdmin && (
						<Tooltip title='SuperAdmin בלבד'>
							<span>
								<EditButton />
								<DeleteButton mutationMode='pessimistic' />
							</span>
						</Tooltip>
					)}
				</Stack>
			}>
			<TabbedShowLayout>
				<Tab label='Info' hidden={!isSuperAdmin}>
					<TextField source='email' fullWidth />
					<TextField source='first_name' fullWidth />
					<TextField source='last_name' fullWidth />
					<TextField source='phone' fullWidth />
					<TextField source='push_token' fullWidth />
					<ReferenceField source='company' reference='company'>
						<TextField source='english_name' />
					</ReferenceField>
					<TextField source='platform' label='מערכת' textAlign='right' />
					<TextField source='version' label='גרסה מותקנת' textAlign='right' />
					{permissions === 'superadmin' && (
						<Button color='error' style={{ alignSelf: 'center' }} onClick={logoutUser}>
							התנתקות מכל המכשירים
						</Button>
					)}
				</Tab>
				<Tab label='Meetings' hidden={!isSuperAdmin}>
					<List>
						{meetings?.map((x) => (
							<ListItem disablePadding>
								<ListItemButton style={{ padding: '0 10px' }} href={`#/meeting/${x._id}/show`}>
									<ListItemText
										style={{ textAlign: 'start' }}
										primary={x.title}
										secondary={
											<>
												<Typography sx={{ display: 'inline' }} variant='body1'>
													{moment(x.time).format('DD/MM/YY HH:mm')}
												</Typography>
											</>
										}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Tab>
				<Tab label='Messages' hidden={!isSuperAdmin}>
					<SendMemberMessages />
				</Tab>

				{record?._id ? (
					<Tab label='תכנית פרואקטיבית'>
						<MemberProactivePlanForm member_id={record?._id} />
					</Tab>
				) : null}
			</TabbedShowLayout>
		</Show>
	);
};
export default MemberShow;
