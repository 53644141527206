import { Box, Grid } from '@mui/material';
import { Edit, ImageField, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput, useEditController } from 'react-admin';
import ImageDropZone from '../ImageDropZone';
import React from 'react';

const LectureEdit = () => {
	const context = useEditController();
	const fields: { fieldName: string; label: string; required?: boolean }[] = [
		{ fieldName: 'title', label: 'כותרת', required: true },
		{ fieldName: 'expert_name', label: 'מומחה', required: true },
		{ fieldName: 'duration', label: 'משך' },
		{ fieldName: 'technical_requirements', label: 'דרישות טכניות' },
		{ fieldName: 'price', label: 'עלות' },
		{ fieldName: 'audience', label: 'קהל יעד' },
		{ fieldName: 'cancellation_policy', label: 'תנאי ביטול' },
	];
	return (
		<Edit
			redirect='edit'
			transform={(data: any) => {
				delete data.image;
				delete data.expert_image;
				return data;
			}}>
			<SimpleForm>
				<Grid container spacing={2}>
					{fields.map((field) => (
						<React.Fragment key={`${Math.floor(Math.random() * 999999)}`}>
							<Grid item sm={4}>
								<TextInput source={`${field.fieldName}.he`} fullWidth required={field.required} label={`${field.label} עב'`} />
							</Grid>
							<Grid item sm={4}>
								<TextInput source={`${field.fieldName}.en`} fullWidth required={field.required} label={`${field.label} אנג'`} />
							</Grid>
							<Box width='100%' />
						</React.Fragment>
					))}
					<Grid item sm={6}>
						<TextInput multiline source='description.he' fullWidth />
					</Grid>
					<Grid item sm={6}>
						<TextInput multiline source='description.en' fullWidth />
					</Grid>

					<Grid item sm={6}>
						<ReferenceArrayInput source='category' reference='lecture/category'>
							<SelectArrayInput optionText={'name.he'} fullWidth />
						</ReferenceArrayInput>
					</Grid>
				</Grid>
				<ImageField source='image' label='תמונת הרצאה' title='תמונה' />
				<ImageField source='expert_image' label='תמונת מרצה' />
				<ImageDropZone
					title='תמונת הרצאה'
					resource={'lecture'}
					id={context.record?._id}
					field={'image'}
					onUpdate={() => context.refetch({})}
				/>
				<ImageDropZone
					title='תמונת מומחה'
					resource={'lecture'}
					id={context.record?._id}
					field={'expert_image'}
					onUpdate={() => context.refetch()}
				/>
			</SimpleForm>
		</Edit>
	);
};

export default LectureEdit;
