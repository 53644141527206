import { Datagrid, List, TextField } from 'react-admin';

const LectureCategoryList = () => {
  return (
    <List>
      <Datagrid rowClick='edit'>
        <TextField source='name.he' textAlign='right' label='שם' />
      </Datagrid>
    </List>
  );
};

export default LectureCategoryList;
