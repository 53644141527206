import { v4 as uuidv4 } from 'uuid';

const AsideChangeLog = ({ items }: { items: string[] }) => {
  return items.length > 0 ? (
    <div style={{ padding: 10, width: 300, textAlign: 'right' }}>
      <h4>שינויים שנוספו</h4>
      <ul>
        {items.map((x) => (
          <li key={`${uuidv4()}`}>{x}</li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default AsideChangeLog;
