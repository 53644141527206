import { Box, Button, Dialog, Grid, Input, Stack, Typography } from '@mui/material';

import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { RichTextInput } from 'ra-input-rich-text';
import { Reducer, useEffect, useReducer, useState } from 'react';
import {
    ArrayInput,
    BooleanInput,
    Datagrid,
    DateInput,
    Edit,
    FormTab,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceManyField,
    SaveButton,
    SelectArrayInput,
    SimpleFormIterator,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    useEditController,
    useNotify,
    usePermissions,
} from 'react-admin';
import { CgMathPlus, CgTrash } from 'react-icons/cg';
import { GiCancel } from 'react-icons/gi';
import constants from '../../constants';
import useFetch from '../../hooks/useFetch';
import { getLocalizedErrorMessage } from '../../utils/functions';
import MyPagination from '../MyPagination';

const CompanyToolBar = (props: any) => (
    <Toolbar>
        <SaveButton />
    </Toolbar>
);
const CompanyInfoText = (record: any) => {
    return (
        <Stack>
            <RichTextInput source='subscription_info.he' label='מה מגיע לי (עברית)' />
            <RichTextInput source='subscription_info.en' label='מה מגיע לי (אנגלית)' textAlign={'right'} />
        </Stack>
    );
};

type HRInfo = { first_name: string; last_name: string; email: string; _id: string };
const HREdit = ({ company_id }: { company_id: string }) => {
    const HR_URL = `${constants.API_URL}/company/${company_id}/hr`;
    const { data: hrs, fetchAgain } = useFetch<HRInfo[]>(HR_URL);
    const [hrEmail, setHrEmail] = useState('');
    const snackbar = useNotify();
    const [isLoading, setisLoading] = useState(false);
    const handleRemoveHR = async ({ user_id }: { user_id: string }) => {
        try {
            setisLoading(true);
            await axios.delete(HR_URL, { data: { member_id: user_id } });
            await fetchAgain();
            snackbar('HR הוסר/ה בהצלחה', { type: 'success' });
        } catch (error) {
            snackbar(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
        } finally {
            setisLoading(false);
        }
    };
    const handleAddHR = async ({ email }: { email: string }) => {
        try {
            setisLoading(true);
            await axios.post(HR_URL, { email });
            await fetchAgain();
            snackbar(`${email} נוסף/ה בהצלחה`, { type: 'success' });
        } catch (error) {
            snackbar(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
        } finally {
            setisLoading(false);
        }
    };
    return (
        <div>
            <BooleanInput source='showHrSection' label='מתחם HR זמין'/>
            <h2 className='font-bold'>מנהלי/ות HR בחברה</h2>
            <table className='border-separate border-spacing-x-3 border-spacing-y-2'>
                <thead>
                    <tr>
                        <th>שם</th>
                        <th>מייל</th>
                        <th>הסרה</th>
                    </tr>
                </thead>
                {hrs?.map((hr) => (
                    <tr>
                        <td>{`${hr.first_name} ${hr.last_name}`}</td>
                        <td>{hr.email}</td>
                        <td>
                            <Button onClick={() => handleRemoveHR({ user_id: hr._id })} disabled={isLoading}>
                                <CgTrash color='red' />
                            </Button>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={2}>
                        <Input disabled={isLoading} value={hrEmail} onChange={(e) => setHrEmail(e.target.value)} fullWidth placeholder='הוספת מייל HR' />
                    </td>
                    <td>
                        <Button onClick={() => handleAddHR({ email: hrEmail })} disabled={isLoading}>
                            <CgMathPlus />
                        </Button>
                    </td>
                </tr>
            </table>
        </div>
    );
};

const CompanyEmployees = () => (
    <div>
        <h3>עובדי חברה</h3>
        <ReferenceManyField pagination={<MyPagination />} perPage={20} reference='member' target='company'>
            <Datagrid bulkActionButtons={false} rowClick='show'>
                <TextField source='email' />
                <TextField source='first_name' />
                <TextField source='last_name' />
            </Datagrid>
        </ReferenceManyField>
    </div>
);
const FactorsModal = ({ onFactorUpdate, open, onClose }: { open: boolean; onFactorUpdate: (name: string, value: number) => Promise<void>; onClose: () => void }) => {
    type State = { name: string; value: number };
    const initialState: State = { name: '', value: 0 };
    type Actions = { payload: number; type: 'SET_VALUE' } | { payload: string; type: 'SET_NAME' } | { type: 'RESET' };
    const reducer: Reducer<State, Actions> = (state, action) => {
        switch (action.type) {
            case 'SET_NAME':
                return { ...state, name: action.payload };
            case 'SET_VALUE':
                return { ...state, value: action.payload };
            case 'RESET':
                return initialState;
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        if (open) {
            dispatch({ type: 'RESET' });
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <div className='p-4'>
                <div className='flex flex-row gap-2 mb-4'>
                    <Input placeholder='Factor Name' value={state.name} onChange={(e) => dispatch({ type: 'SET_NAME', payload: e.target.value })} />
                    <Input
                        placeholder='Factor Value'
                        value={state.value}
                        onChange={(e) => {
                            if (e.target.value) dispatch({ type: 'SET_VALUE', payload: parseInt(e.target.value ?? 0) });
                            else dispatch({ type: 'SET_VALUE', payload: 0 });
                        }}
                    />
                </div>
                <div className='text-end'>
                    <Button
                        onClick={() => {
                            onFactorUpdate(state.name, state.value);
                        }}>
                        עדכון
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
const CompanyEdit = () => {
    const { permissions } = usePermissions();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const handleDeleteCompanyButtonPress = () => {
        setShowDeleteDialog(true);
    };
    const { record, refetch } = useEditController();
    const notify = useNotify();
    const [deleteVerificationText, setDeleteVerificationText] = useState('');
    const handleDeleteConfirm = async () => {
        try {
            const res = await axios.delete(`${constants.API_URL}/company/${record._id}/delete_users`, {
                data: { company_id: record._id, verification: deleteVerificationText },
            });
            notify(`${res.data?.count || 'כל'} עובדי ${record?.english_name} נמחקו`);
            handleCloseDialog();
        } catch (error) {}
    };
    const handleCloseDialog = () => {
        setShowDeleteDialog(false);
        setDeleteVerificationText('');
    };

    const [showUdiFactorDialog, setShowUdiFactorDialog] = useState(false);
    const udiFactors = record?.udiFactors as Map<string, { value: number; updatedAt: Date; active: boolean }>;

    return (
        <>
            <FactorsModal
                onClose={() => {
                    setShowUdiFactorDialog(false);
                }}
                open={showUdiFactorDialog}
                onFactorUpdate={async (name: string, value: number) => {
                    try {
                        await axios.patch(`${constants.API_URL}/company/${record._id}/udi_update_factor`, { name, value });
                        setShowUdiFactorDialog(false);
                        notify('בוצע בהצלחה', { type: 'success' });
                        refetch();
                    } catch (error) {
                        notify(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
                    }
                }}
            />
            <Edit mutationMode='pessimistic'>
                <Dialog open={showDeleteDialog} onClose={handleCloseDialog}>
                    <Box p={2} display='flex' flexDirection='column' gap={2}>
                        <Typography fontStyle={'italic'} display={'inline'}>
                            {'יש לכתוב '}
                            <Typography fontWeight={'bold'} display={'inline'}>
                                {record?.english_name}
                            </Typography>
                            {' כדי להמשיך'}
                        </Typography>
                        <Input onChange={(e) => setDeleteVerificationText(e.target.value)} value={deleteVerificationText} />
                        <Button disabled={record?.english_name !== deleteVerificationText} color='error' onClick={handleDeleteConfirm}>
                            אישור מחיקה
                        </Button>
                    </Box>
                </Dialog>
                <TabbedForm toolbar={<CompanyToolBar />}>
                    <FormTab label={'מידע כללי'}>
                        <Grid container justifyContent='flex-start' spacing={2}>
                            <Grid item xs={12} textAlign={'start'}>
                                <TextInput label='שם חברה' source='english_name' />
                            </Grid>

                            <Box width='100%' />
                            <Grid item sm={3}>
                                <ArrayInput source='company_email_domains' label='דומיין חברה'>
                                    <SimpleFormIterator disableClear fullWidth>
                                        <TextInput source='' helperText={false} fullWidth margin='dense' />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                            <Grid item sm={3}>
                                <ArrayInput source='allowed_emails' label='אימיילים מאושרים'>
                                    <SimpleFormIterator disableClear fullWidth>
                                        <TextInput source='' helperText={false} fullWidth margin='dense' />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                            <Box width='100%' />
                            <Grid item sm={3} alignContent={'center'}>
                                <ImageField source='logo' sx={{}} />
                            </Grid>
                            <Grid item sm={3} alignContent={'center'}>
                                <TextInput source='logo_background' label='רקע לוגו' type='color' style={{ width: 150 }} />
                            </Grid>
                            <Grid item sm={3}>
                                <ImageInput label='החלפת לוגו' source='picture'>
                                    <ImageField source='src' />
                                </ImageInput>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6' textAlign={'start'}>
                                    מתחמים
                                </Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <BooleanInput source='helping_hand' label='עזרה ראשונה (Helping hand)' />
                            </Grid>
                            <Grid item sm={2}>
                                <BooleanInput label='הזמנת בן משפחה' fullWidth source='invite_family_members' />
                            </Grid>
                            <Grid item sm={2}>
                                <BooleanInput label='תכנית פרואקטיבית?' fullWidth source='allow_proactive_app' />
                            </Grid>
                            <Grid item sm={3} alignContent={'center'}>
                                <SelectArrayInput
                                    style={{ marginTop: -10 }}
                                    fullWidth
                                    choices={[
                                        { name: 'Dating', id: 'DATING' },
                                        { name: 'Care Givers', id: 'CARE_GIVERS' },
                                        { name: 'Cool Stuff', id: 'COOL_STUFF' },
                                        { name: 'קשב וריכוז', id: 'ADHD' },
                                    ]}
                                    source='imagebuttons_names'
                                />
                            </Grid>

                            <Box width='100%' />

                            <Grid item xs={12}>
                                <Typography variant='h6' textAlign={'start'}>
                                    מרחב בטוח
                                </Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <BooleanInput source='safe_space' label='המרחב הבטוח' />
                            </Grid>

                            <Grid item sm={3}>
                                <BooleanInput source='safe_space_for_family_members' label='הצגת מרחב בטוח לבני משפחה' />
                            </Grid>
                            <Box width='100%' />
                            <Grid item sm={4}>
                                <TextInput fullWidth source='safe_space_text_lng.he' label='טקסט מרחב בטוח' multiline />
                            </Grid>
                            <Grid item sm={4}>
                                <TextInput fullWidth source='safe_space_text_lng.en' label="טקסט מרחב בטוח (אנ')" multiline />
                            </Grid>

                            <Box width='100%' />
                            <Grid item xs={12}>
                                <Typography variant='h6' textAlign={'start'}>
                                    טקסט פתיחה
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <TextInput fullWidth source='hello_text' label='כותרת לטקסט פתיחה' />
                            </Grid>
                            <Grid item sm={12}>
                                <TextInput fullWidth source='text' label='טקסט פתיחה' multiline />
                            </Grid>
                            <Grid item sm={2}>
                                <BooleanInput label='שליחת טקסט פתיחה?' fullWidth source='send_welcome_text' />
                            </Grid>

                            <Box width='100%' />
                            <Grid item sm={3}>
                                <ArrayInput source='safe_space_prices' label='מחירי מרחב בטוח'>
                                    <SimpleFormIterator disableClear fullWidth getItemLabel={(index: number) => <Typography width={60}>{`${index + 1}. `}</Typography>}>
                                        <NumberInput source='price' fullWidth />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                            <Box width='100%' />
                            <Grid item sm={2}>
                                <DateInput source='subscription_start' label='תחילת המנוי' />
                            </Grid>
                            <Grid item sm={2}>
                                <BooleanInput source='active' label='חברה פעילה' />
                            </Grid>
                            <Grid item sm={12}>
                                <div className='text-start'>
                                    {udiFactors ? (
                                        <table className='table-fixed border-separate border-spacing-x-5'>
                                            <thead>
                                                <th className='text-start w-44 '>שם</th>
                                                <th className='text-start'>ערך</th>
                                                <th className='text-start w-32'></th>
                                                <th className='text-start'></th>
                                            </thead>
                                            {Object.entries(udiFactors).map(([key, valueObj]) => (
                                                <tr className='h-8'>
                                                    <td className={`text-start text-sm ${!valueObj.active ? 'line-through' : ''}`}>{key ?? 0}</td>
                                                    <td className={`text-start text-sm font-bold ${!valueObj.active ? 'line-through' : ''}`}>{valueObj.value}</td>
                                                    <td dir='ltr' className='text-left text-xs'>
                                                        {moment(valueObj.updatedAt).fromNow()}
                                                    </td>
                                                    <td className=''>
                                                        <Button
                                                            disabled={!valueObj.active}
                                                            variant='text'
                                                            onClick={async () => {
                                                                axios.delete(`${constants.API_URL}/company/${record._id}/udi_deactivate_factor`, { data: { name: key } });

                                                                refetch();
                                                            }}>
                                                            <GiCancel />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    ) : null}
                                    <div className=''>
                                        <Button
                                            variant='contained'
                                            className='block'
                                            onClick={() => {
                                                setShowUdiFactorDialog(true);
                                            }}>
                                            עריכת פקטורים
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </FormTab>
                    <FormTab label={'מה מגיע לי'}>
                        <CompanyInfoText />
                    </FormTab>
                    <FormTab label='HR'>{record?._id ? <HREdit company_id={record?._id} /> : null}</FormTab>
                    <FormTab label={'נתונים למתחם HR'}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <NumberInput source='satisfaction' step={0.1} label='מדד שביעות רצון' fullWidth />
                            </Grid>
                            <Grid item xs={3}>
                                <NumberInput source='perceived_value' step={0.1} label='הערך הנתפס' fullWidth />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={3}>
                                <NumberInput fullWidth source='old_platform_subscribers' label='משתמשים באתר הישן' />
                            </Grid>
                            <Grid item xs={3}>
                                <NumberInput fullWidth source='computed_usages' label="מס' שימושים משוקלל" />
                            </Grid>
                        </Grid>
                    </FormTab>
                    <FormTab label={'מילואימניקים'}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <NumberInput sx={{ marginTop: '20px' }} source='miluim.price' step={0.1} label='מחיר למפגש' fullWidth />
                            </Grid>

                            <Grid item xs={3}>
                                <NumberInput sx={{ marginTop: '20px' }} fullWidth source='miluim.number_of_sessions' label='מספר מפגשים' />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={4}>
                                <ArrayInput source='miluim.emails' label='אימיילים'>
                                    <SimpleFormIterator inline disableClear fullWidth>
                                        <TextInput source='' helperText={false} fullWidth />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                        </Grid>
                    </FormTab>
                    <FormTab label={'עובדים'}>
                        <CompanyEmployees />
                    </FormTab>
                    {permissions === 'superadmin' && (
                        <FormTab borderColor={'red'} label='סכנה'>
                            <Button onClick={handleDeleteCompanyButtonPress} color='error' variant='contained'>
                                מחיקת חברה
                            </Button>
                        </FormTab>
                    )}
                </TabbedForm>
            </Edit>
        </>
    );
};

export default CompanyEdit;
