import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import constants from '../../constants';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectPrivateServiceMultiple = ({
    onExpertChange,
    onServiceChange,
    selectedExpertIds,
    selectedServiceIds,
    horizontal,
    disabled = false,
}: {
    onExpertChange: (expert_ids: string[]) => void;
    onServiceChange: (expert_ids: string[]) => void;

    selectedExpertIds: string[] | null;
    selectedServiceIds: string[] | null;
    horizontal?: boolean;
    disabled?: boolean;
}) => {
    const experts = useGetList('instructor/all', { pagination: { perPage: 1000, page: 1 }, sort: { field: 'name', order: 'ASC' } });
    const [services, setServices] = useState<any[]>([]);

    useEffect(() => {
        const getServices = async () => {
            setServices([]);

            setServices(
                (
                    await axios.get(`${constants.API_URL}/instructor/private_services`, {
                        params: { instructor_id: selectedExpertIds },
                    })
                ).data
            );
        };
        getServices();
    }, [selectedExpertIds]);

    return (
        <div style={{ display: 'flex', flexDirection: horizontal ? 'row' : 'column', gap: 20 }}>
            <FormControl>
                <InputLabel id='demo-simple-select-label'>מומחה</InputLabel>
                <Select
                    disabled={disabled}
                    size='small'
                    label='מומחה'
                    multiple
                    style={{ minWidth: 300 }}
                    value={selectedExpertIds}
                    onChange={(e, v) => {
                        onExpertChange(typeof e.target.value === 'string' ? [e.target.value] : e.target.value ?? []);
                    }}>
                    {experts.data?.map((expert: any) => (
                        <MenuItem value={expert._id}>{expert.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id='demo-simple-select-label'>שירות</InputLabel>
                <Select
                    disabled={disabled}
                    size='small'
                    label='שירות'
                    style={{ minWidth: 300, maxWidth: 300 }}
                    multiple
                    multiline
                    value={selectedServiceIds}
                    onChange={(e, v) => {
                        onServiceChange(typeof e.target.value === 'string' ? [e.target.value] : e.target.value ?? []);
                    }}>
                    {services?.map((service: any) => (
                        <MenuItem value={service._id}>
                            <span>
                                {`${service.title.he}`}
                                <span style={{ fontSize: 10 }}>{` ${experts.data?.find((expert) => expert._id === service.host).name}`}</span>
                            </span>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
export default SelectPrivateServiceMultiple;
