import { ChipField, Datagrid, FunctionField, List, ReferenceArrayField, SingleFieldList, TextField } from 'react-admin';

const SectionList = () => {
  return (
    <List>
      <Datagrid rowClick='edit'>
        <TextField source='slug' label='שם' textAlign='right' />
        <ReferenceArrayField
          textAlign='right'
          source='experts_ids'
          reference='instructor/all'
          resource='instructor'
          label='מומחים'
        >
          <SingleFieldList>
            <ChipField clickable={false} source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          label='צבע'
          textAlign='right'
          source='color'
          render={(record: any) => (
            <div style={{ height: 10, width: 10, borderRadius: 10, backgroundColor: record.color }} />
          )}
        />
      </Datagrid>
    </List>
  );
};

export default SectionList;
