import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { useState } from 'react';
import {
	BooleanInput,
	Edit,
	FormTab,
	ImageField,
	ImageInput,
	NumberInput,
	ReferenceArrayInput,
	SelectArrayInput,
	TabbedForm,
	TextField,
	TextInput,
	useEditController,
	useNotify,
} from 'react-admin';
import { Personalization } from '../Meeting/MeetingCreate';
import SelectMeetingByDate from '../SelectMeetingByDate';
import constants from '../../constants';
import axios, { AxiosError } from 'axios';

const VodEdit = () => {
	const record = useEditController();
	const [ignoreAge, setIgnoreAge] = useState(false);

	const transform = (data: any) => ({
		...data,
		age: ignoreAge ? { to: null, from: null } : data.age,
	});
	const notify = useNotify();
	return (
		<Edit transform={transform} mutationMode='pessimistic' resource={'vod/admin'} redirect={'/vod'}>
			<TabbedForm>
				<FormTab label='פרטים'>
					<TextField source='id' mb={5} />
					<TextInput source='title' label='שם' dir='rtl' style={{ direction: 'rtl' }} fullWidth />
					<TextInput source='sub_title' label='תת כותרת' dir='rtl' style={{ direction: 'rtl' }} fullWidth />
					<TextInput source='videoUrl' label='קישור לוידאו' fullWidth dir='ltr' />

					<ImageInput source='picture' label='Logo'>
						<ImageField source='src' />
					</ImageInput>
					<ReferenceArrayInput reference='vod/categories/all' source='category'>
						<SelectArrayInput optionText={'hebrew_name'} />
					</ReferenceArrayInput>
					<Personalization setIgnoreAge={setIgnoreAge} ignoreAge={ignoreAge} />
					<TextInput source='description' label='תיאור' fullWidth multiline />
					<NumberInput source='priority' label='קדימות' fullWidth />
					<BooleanInput source='visible' label='זמין' fullWidth />
				</FormTab>
				<FormTab label='הקלטה'>
					<Box py={2} mx={'auto'}>
						<Card style={{ width: 600 }}>
							<CardHeader title='הקלטה'></CardHeader>
							<CardContent>
								<SelectMeetingByDate
									initValue={record.record?.recorded_meeting}
									onMeetingUpdate={async (id) => {
										try {
											await axios.put(`${constants.API_URL}/meeting/${id}/record`, { video_id: record.record._id });
											notify('ההקלטה נשמרה', { type: 'success' });
										} catch (error) {
											notify(`משהו השתבש (${(error as AxiosError).response?.status})`, { type: 'error' });
										}
									}}
								/>
							</CardContent>
						</Card>
					</Box>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
export default VodEdit;
