import { Datagrid, ImageField, List, NumberField, TextField } from 'react-admin';

const ProactiveItemList = () => {
	return (
		<List>
			<Datagrid rowClick='edit' dir='rtl' bulkActionButtons={false}>
				<TextField source='title.he' label={'שם'} />
				<NumberField source='points' label='נקודות' />
				<TextField source='slug' />
				<TextField source='__t' label='סוג' />
				<TextField source='type' label='סוג תוכן' />
				<ImageField source='image' />
			</Datagrid>
		</List>
	);
};

export default ProactiveItemList;
