import { Grid, InputAdornment } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateInput, Edit, FormDataConsumer, NumberInput, SelectInput, SimpleForm, TextInput, useEditController } from 'react-admin';
import constants from '../../constants';
import SelectPrivateServiceMultiple from '../PrivateSessions/SelectPrivateServiceMultiple';
import { PaymentProductType, productTitles } from './Coupon.types';

const CouponEdit = () => {
    const [expertIds, setExpertIds] = useState<string[]>([]);
    const [serviceIds, setServiceIds] = useState<string[]>([]);
    const [isHorizontal, setIsHorizontal] = useState(window.innerWidth > 300);
    const { record } = useEditController(); // Get the record from react-admin

    useEffect(() => {
        const fetchExperts = async () => {
            console.log('run');

            console.log('record', record);
            if (record?.applicableServices) {
                console.log('run2');
                const experts: string[] = [];
                await Promise.all(
                    record?.applicableServices.map(async (service: string) => {
                        if (service) {
                            const res = await axios.get(`${constants.API_URL}/instructor/private_services/${service}`);
                            if (res.data?.host) experts.push(res.data?.host);
                        }
                    })
                );
                setExpertIds(experts);
                setServiceIds(record?.applicableServices);
            }
        };
        fetchExperts();
    }, [record]);
    // Effect to update the state on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsHorizontal(window.innerWidth > 800);
        };

        // Attach the event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <Edit transform={(data: any) => ({ ...data, applicableServices: serviceIds })}>
            <SimpleForm>
                <Grid container columnSpacing={2} maxWidth={800}>
                    <Grid item xs={12}>
                        <TextInput required fullWidth source='description' label='תיאור' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput required fullWidth source='code' label='קוד קופון' />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SelectInput
                            fullWidth
                            margin='none'
                            label='סוג ההנחה'
                            source='discountType'
                            choices={[
                                { id: 'percentage', name: 'אחוזים' },
                                { id: 'fixed', name: 'קבועה' },
                            ]}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormDataConsumer fullWidth>
                            {({ formData }) => (
                                <TextInput
                                    fullWidth
                                    InputLabelProps={{ dir: 'rtl', sx: { textAlign: 'right' } }}
                                    margin='none'
                                    label='שווי'
                                    source='discountValue'
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                {formData.discountType === 'fixed' ? '₪' : formData.discountType === 'percentage' ? '%' : ''}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </FormDataConsumer>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SelectInput
                            required
                            fullWidth
                            margin='none'
                            label='מוצר'
                            source='product'
                            choices={[...Object.values(PaymentProductType).map((x) => ({ id: x, name: productTitles[x] })), { id: 'all', name: 'הכל' }]}
                        />
                    </Grid>
                    <Grid item mb={3}>
                        <FormDataConsumer fullWidth>
                            {({ formData }) => (
                                <SelectPrivateServiceMultiple
                                    horizontal={isHorizontal}
                                    disabled={!formData.product?.includes('private')}
                                    onExpertChange={setExpertIds}
                                    onServiceChange={setServiceIds}
                                    selectedExpertIds={expertIds}
                                    selectedServiceIds={serviceIds}
                                />
                            )}
                        </FormDataConsumer>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DateInput inputProps={{ min: moment().format('YYYY-MM-DD') }} fullWidth margin='none' label='תוקף' source='expirationDate' required />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <NumberInput min={1} fullWidth margin='none' label='הגבלת שימוש' source='usageLimit' required />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

export default CouponEdit;
