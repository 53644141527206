import { BooleanField, Datagrid, FunctionField, ImageField, List, NumberField, TextField } from 'react-admin';

const PrizeList = () => (
	<List>
		<Datagrid rowClick='edit'>
			<TextField source='title.he' />
			<NumberField source='points' />
			<FunctionField
				label='קופונים'
				render={(record: any) => {
					const redeemedCount = record.coupons.filter((coupon: { redeemed: boolean }) => coupon.redeemed)?.length;
					return `${redeemedCount}/${record.coupons?.length}`;
				}}
			/>
			<BooleanField source='visible' />
			<TextField source='baseLink' />
			<ImageField source='thumbnail' textAlign='center' />
			<ImageField source='imageUrl' label='תמונה' textAlign='center' />
			{/* <FunctionField render={()=><div className='flex flex-1 w-full'></div>}/> */}
		</Datagrid>
	</List>
);

export default PrizeList;
