import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { IPrivateSessionAppointment } from '../../../types';
import moment from 'moment';

const DeleteSessionDialog = ({
	open,
	sessionToDelete,
	onClose,
	handleCancelSession,
}: {
	open: boolean;
	sessionToDelete: IPrivateSessionAppointment | null;
	onClose: () => void;
	handleCancelSession: (id: string, silent?: boolean) => Promise<void>;
}) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>מחיקת פגישה</DialogTitle>
			<DialogContent>
				<Typography variant='body1' whiteSpace={'pre-line'} textAlign={'center'}>{`בטוח/ה שברצונך למחוק את הפגישה?\n${moment(
					sessionToDelete?.time
				).format('DD/MM HH:mm')}\n${sessionToDelete?.email}`}</Typography>
			</DialogContent>
			<DialogActions>
				<Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
					<Button onClick={onClose}>ביטול</Button>
					<Button
						color='error'
						onClick={() => {
							if (sessionToDelete?._id) handleCancelSession(sessionToDelete?._id);
						}}>
						מחיקה
					</Button>
					<Button
						onClick={() => {
							if (sessionToDelete?._id) handleCancelSession(sessionToDelete?._id, true);
						}}
						color='error'
						style={{ display: 'flex', flexDirection: 'column' }}>
						<Typography variant='body2' display={'block'}>
							מחיקה שקטה
						</Typography>
						<Typography variant='body2' display={'block'} fontSize={10}>
							ללא שליחת מייל
						</Typography>
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteSessionDialog;
