import { Button, Dialog, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { BooleanField, BooleanInput, CreateButton, Datagrid, FilterButton, FunctionField, List, NumberField, TextInput, TopToolbar, useResourceContext } from 'react-admin';
import { BiCalendar, BiLink } from 'react-icons/bi';
import FreeBusyCalendar from '../FreeBusyCalendar/FreeBusyCalendar';
import MyPagination from '../MyPagination';

const ExpertList = () => {
    const [idForFreeBusy, setIdForFreeBusy] = useState<string | null>(null);
    const [freeBusyDialogTitle, setFreeBusyDialogTitle] = useState('');
    const context = useResourceContext({ resource: 'instructor' });
    const filters = [
        <TextInput resource={context} source='name' />,
        <BooleanInput resource={context} source='active' />,
        <BooleanInput source='showInSubscription' label='מופיע/ה במנוי' />,
        <BooleanInput source='safe_space' label='מרחב בטוח' />,
    ];
    return (
        <>
            <Dialog open={Boolean(idForFreeBusy)} maxWidth={'xl'} onClose={() => setIdForFreeBusy(null)}>
                <div className='p-4'>{idForFreeBusy ? <FreeBusyCalendar title={freeBusyDialogTitle} id={idForFreeBusy} /> : null}</div>
            </Dialog>
            <List
                resource='instructor/all'
                filters={filters}
                actions={
                    <TopToolbar>
                        <CreateButton resource='instructor' />
                        <FilterButton />
                    </TopToolbar>
                }
                perPage={50}
                pagination={<MyPagination />}>
                <Datagrid rowClick='edit' dir='rtl' resource={context} size='small' style={{ tableLayout: 'auto' }} bulkActionButtons={false}>
                    <BooleanField source='active' label='פעיל/ה' />
                    <FunctionField
                        width={20}
                        sortBy='name'
                        label='שם'
                        render={(record: any) => {
                            let anchorPercent;
                            switch (record.anchorImage) {
                                case 'top':
                                    anchorPercent = 0;
                                    break;
                                case 'bottom':
                                    anchorPercent = 100;
                                    break;
                                default:
                                    anchorPercent = 50;
                                    break;
                            }

                            return (
                                <div className='flex flex-row gap-2 items-center'>
                                    <img
                                        alt={`${record.english_name}`}
                                        src={record.image}
                                        height={50}
                                        width={50 * 1.3869346734}
                                        style={{ objectFit: 'cover', objectPosition: `50% ${anchorPercent}%` }}
                                    />
                                    <div>
                                        <span className='flex flex-col justify-between h-full'>
                                            <div className='flex flex-row gap-2 items-center'>
                                                <span className='font-bold text-lg'>{record.name}</span>
                                                {Boolean(record.google) ? (
                                                    <Tooltip title='יומן גוגל מסונכרן'>
                                                        <span>
                                                            <i className='fab fa-google fa-1x' />
                                                        </span>
                                                    </Tooltip>
                                                ) : null}
                                            </div>
                                            <div>
                                                <span className='text-xs max-w-80'>{record.title} </span>
                                                <div>
                                                    {record.category?.length ? (
                                                        <Tooltip
                                                            title={
                                                                <ul className=' leading-tight list-disc'>
                                                                    {record.category.map((category: any) => (
                                                                        <li className='text-xs '>{category.hebrew_name}</li>
                                                                    ))}
                                                                </ul>
                                                            }>
                                                            <span className=''>{record.category?.length === 1 ? 'קטגוריה אחת' : `${record.category?.length} קטגוריות`}</span>
                                                        </Tooltip>
                                                    ) : null}
                                                </div>
                                                {record.safe_space ? <div className='font-bold text-xs'>מרחב בטוח</div> : null}
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            );
                        }}
                    />
                    <NumberField source='priority' />
                    <FunctionField
                        textAlign='right'
                        render={(record: any) => {
                            const warnings = [];
                            if (!record.email) warnings.push('אין אימייל');
                            if (!record.image) warnings.push('אין תמונה');
                            if (!record.title) warnings.push('אין טייטל');
                            if (!record.english_title) warnings.push('אין טייטל באנגלית');
                            if (!record.english_name) warnings.push('אין שם באנגלית');
                            if (!record.zoom) warnings.push('אין קישור זום');

                            return (
                                <Typography variant='caption' textAlign={'right'} color='#CA0B00'>
                                    {warnings.join(', ')}
                                </Typography>
                            );
                        }}
                    />
                    <FunctionField
                        label='קישור לעמוד'
                        textAlign='right'
                        render={(record: any) => {
                            return (
                                <Button
                                    variant='text'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(`https://app.grouphug.online/he/expert/${record._id}/`);
                                        return;
                                    }}>
                                    <BiLink />
                                </Button>
                            );
                        }}
                    />
                    <FunctionField
                        label='בדיקת יומן'
                        textAlign='right'
                        render={(record: any) => {
                            return (
                                <Button
                                    variant='text'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIdForFreeBusy(record._id);
                                        setFreeBusyDialogTitle(record.name);
                                        return;
                                    }}>
                                    <BiCalendar />
                                </Button>
                            );
                        }}
                    />
                </Datagrid>
            </List>
        </>
    );
};

export default ExpertList;
