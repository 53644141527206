import FullCalendar from '@fullcalendar/react';
import timeGrid from '@fullcalendar/timegrid';
import axios from 'axios';
import { useEffect, useState } from 'react';
import constants from '../../constants';
const getColorByType = (type: string) => {
    switch (type) {
        case 'google':
            return { backgroundColor: 'rgba(255, 0,0, 1)', title: 'חסימת יומן', borderColor: 'transparent', display: 'block' };
        case 'appointment':
            return { backgroundColor: 'purple', title: 'פגישה בגרופהאג', borderColor: 'transparent', display: 'block' };
        case 'vacation':
            return { backgroundColor: 'green', title: 'חופשה', borderColor: 'transparent', display: 'block' };
        case 'working_hours':
            return { backgroundColor: 'gray', title: 'מחוץ לשעות פעילות', borderColor: 'transparent', display: 'background' };
        default:
            return { backgroundColor: 'lightgray', title: type, borderColor: 'transparent', display: 'auto' };
    }
};
type TypeProps = {
    type: string;
};

type TitleColorProps = {
    title: string;
    color: string;
};

// Use a union type for the props
type CalendarLegendProps = TypeProps | TitleColorProps;

const CalendarLegend = (props: CalendarLegendProps) => {
    const isTypeProps = (props: CalendarLegendProps): props is TypeProps => {
        return (props as TypeProps).type !== undefined;
    };
    const backgroundColor = isTypeProps(props) ? getColorByType(props.type).backgroundColor : props.color;
    const title = isTypeProps(props) ? getColorByType(props.type).title : props.title;

    return (
        <span className='flex flex-row gap-1 items-center'>
            <span
                style={{
                    height: 16,
                    width: 16,
                    borderRadius: 5,
                    backgroundColor: backgroundColor,
                    opacity: 0.6,
                }}
            />
            <span>{title}</span>
        </span>
    );
};
const FreeBusyCalendar = ({ id, title }: { id: string; title?: string }) => {
    const [calRange, setCalRange] = useState([new Date(), new Date()]);
    useEffect(() => {
        const fetchFreeBusy = async () => {
            const [res, freeSlotsRes] = await Promise.all([
                axios.post(`${constants.API_URL}/instructor/busy/v2`, {
                    id,
                    from: calRange[0].toISOString(),
                    to: calRange[1].toISOString(),
                }),
                axios.post(
                    `${constants.API_URL}/instructor/freebusy/host/v2`,
                    {
                        duration: 50,
                        gap: 10,
                        id,
                    },
                    { params: { from_date: calRange[0].toISOString(), to_date: calRange[1].toISOString() } }
                ),
            ]);

            setFreeSlots(freeSlotsRes.data.map((date: any) => date.availableSlots).flat());
            setSlots(res.data);
        };
        fetchFreeBusy();
    }, [calRange, id]);
    const [slots, setSlots] = useState<any[]>([]);
    const [freeSlots, setFreeSlots] = useState<any[]>([]);
    return (
        <div>
            {title ? <h1 className='text-xl'>{title}</h1> : null}
            <div className='min-w-[800px] p-4'>
                <FullCalendar
                    allDaySlot={false}
                    locale={'he-IL'}
                    direction='rtl'
                    plugins={[timeGrid]}
                    initialView=''
                    events={[
                        ...freeSlots.map((slot) => ({ start: slot.start, end: slot.end, color: '#009900aa', title: 'זמין לקביעה' })),
                        ...slots.map((slot) => {
                            const { backgroundColor, borderColor, title, display } = getColorByType(slot.type);
                            return {
                                start: slot.start,
                                backgroundColor,
                                borderColor,
                                end: slot.end,
                                title,
                                display,
                            };
                        }),
                    ]}
                    datesSet={(args) => {
                        setCalRange([args.start, args.end]);
                    }}
                />
            </div>
            <div>
                <span className='text-sm'>{`בדיקת שעות פנויות לפי פגישות של 50 דקות`}</span>
                <div className='flex flex-row gap-8'>
                    <CalendarLegend type='working_hours' />
                    <CalendarLegend type='google' />
                    <span className='flex flex-row gap-1 items-center'>
                        <span
                            style={{
                                height: 16,
                                width: 16,
                                borderRadius: 5,
                                backgroundColor: '#009900aa',
                                opacity: 0.6,
                            }}
                        />
                        <span>שעות פנויות לקביעה</span>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default FreeBusyCalendar;
