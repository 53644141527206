import { Box, Grid } from '@mui/material';
import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin';

const TagCreate = () => (
    <Create>
        <SimpleForm>
            <Grid spacing={2} container>
                <Grid item sm={4}>
                    <TextInput source='name.he' fullWidth />
                </Grid>
                <Grid item sm={4}>
                    <TextInput source='name.en' fullWidth />
                </Grid>
                <Box width='100%' />
                <Grid item sm={4}>
                    <TextInput source='short_name.he' fullWidth label='שם מקוצר (לדוחות) עברית' />
                </Grid>
                <Grid item sm={4}>
                    <TextInput source='short_name.en' fullWidth label='שם מקוצר (לדוחות) אנגלית' />
                </Grid>
                <Grid item sm={4}>
                    <BooleanInput source='show_in_personalization' fullWidth label='הצגה בהתאמה האישית' />
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
export default TagCreate;
