import { Autocomplete, Button, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { AutocompleteInput } from 'react-admin';
import constants from '../constants';
import useFetch from '../hooks/useFetch';
export type SelectMeetingServiceProps = {
	source: string;
	initServiceId?: string | null;
};
const SelectMeetingService = ({ source, initServiceId }: SelectMeetingServiceProps) => {
	const [initServiceMode, setInitServiecMode] = useState(Boolean(initServiceId));
	useEffect(() => {
		setInitServiecMode(Boolean(initServiceId));
	}, [initServiceId]);
	const [selectedExpertId, setSelectedExpertId] = useState<string | null>(null);
	const expertOptions = useMemo(() => ({ params: { filter: { active: true }, sort: [['name', 'ASC']] } }), []);
	const expertServicesOptions = useMemo(() => ({ params: { filter: { host: selectedExpertId }, sort: [['name', 'ASC']] } }), [selectedExpertId]);
	const experts = useFetch<any[]>(`${constants.API_URL}instructor/all`, expertOptions);
	const expertServices = useFetch<any[]>(`${constants.API_URL}service`, expertServicesOptions, selectedExpertId === null);
	const initService = useFetch<any>(`${constants.API_URL}service/${initServiceId}`, {}, initServiceId === undefined);
	const initExpert = useFetch<any>(`${constants.API_URL}instructor/${initService.data?.host[0]}`, {}, !initService.data?.host?.length);

	return (
		<div className='w-full'>
			<table style={{ borderCollapse: 'separate', width: '100%' }} className='border-spacing-x-2'>
				{experts.loading ? (
					<CircularProgress />
				) : initServiceMode ? (
					<tr>
						<td className='w-1/2'>{initExpert?.data?.name}</td>
						<td className='w-1/2'>{initService?.data?.title}</td>
						<td className='w-1/2'>
							<Button onClick={() => setInitServiecMode(false)}>שינוי</Button>
						</td>
					</tr>
				) : (
					<tr style={{}}>
						<td className='w-1/2'>
							<Autocomplete
								options={experts.data?.map((x) => ({ ...x, id: x._id })) ?? []}
								getOptionLabel={(item) => item.name}
								onChange={(e, v) => {
									if (v?._id) {
										setSelectedExpertId(v._id);
									} else setSelectedExpertId(null);
								}}
								renderOption={(props, option, state) => <li {...props}>{option.name}</li>}
								renderInput={(props) => <TextField {...props} placeholder='בחירת מומחה' />}
							/>
						</td>
						<td className='w-1/2'>
							{expertServices.loading ? (
								<CircularProgress size={20} />
							) : (
								<AutocompleteInput
									loading={expertServices.loading}
									margin='none'
									helperText={false}
									disabled={Boolean(selectedExpertId === null)}
									choices={selectedExpertId ? expertServices.data?.map((x) => ({ name: x.title, id: x._id })) ?? [] : []}
									source={source}
									dir='rtl'
									style={{ direction: 'rtl' }}
									label={selectedExpertId ? `${expertServices.data?.length} שירותים` : ''}

									// renderInput={(props) => <TextField {...props} placeholder={`${expertServices.data?.length ? `${expertServices.data.length} שירותים` : ''}`} />}
								/>
							)}
						</td>
					</tr>
				)}
			</table>
		</div>
	);
};

export default React.memo(SelectMeetingService);
