import { Button } from '@mui/material';
import moment from 'moment';
import { PlanResponseProp } from './types.type';

const PlanDetailsTable = ({
	member_id,
	plan,
	resetPoints,
	resetOnboarding,
	regeneratePlan,
}: {
	member_id: string;
	plan?: PlanResponseProp;
	resetPoints: (member_id: string) => void;
	resetOnboarding: () => void;
	regeneratePlan: () => void;
}) => {
	const intervalStrings = ['ERR', 'שבועי', 'כל שבועיים', 'ERR', 'חודשי'];

	const rows = [
		{
			label: 'תאריך התחלה',
			value: plan?.startDate ? moment(plan?.startDate).format('DD/MM/YYYY') : '',
		},
		{
			label: "מס' שבוע בתוכנית",
			value: plan?.weekIndex,
		},
		{
			label: 'עיתוי',
			value: `${intervalStrings[plan?.interval ?? 0]}`,
		},
		{
			label: 'נקודות',
			value: plan?.points,
			component: (
				<Button color='error' disabled={plan?.points === 0} onClick={() => resetPoints(member_id)}>
					איפוס
				</Button>
			),
		},
		{ label: 'נקודות שנצברו', value: plan?.consumedPoints },
		{
			label: 'Onboarding',
			value: (
				<Button disabled={plan?.show_onboarding} onClick={() => resetOnboarding()} color='error'>
					הצג בהפעלה הבאה
				</Button>
			),
		},
		{
			label: 'איפוס התוכנית',
			value: (
				<Button onClick={() => regeneratePlan()} color='error'>
					איפוס
				</Button>
			),
		},
	];

	return (
		<table className='border-spacing-x-4 border-spacing-y-2 border-separate'>
			{rows.map(({ label, value, component }) => (
				<tr className='text-start' key={label}>
					<td className='text-start'>{label}</td>
					<td className='text-start'>{value}</td>
					{component ? <td className='text-start'>{component}</td> : null}
				</tr>
			))}
		</table>
	);
};

export default PlanDetailsTable;
