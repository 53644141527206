import { Button, Stack } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import {
    ArrayField,
    BooleanField,
    BooleanInput,
    ChipField,
    CreateButton,
    Datagrid,
    DateField,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SingleFieldList,
    TextField,
    useListController,
    useRefresh,
} from 'react-admin';
import constants from '../../constants';
import MyPagination from '../MyPagination';
type ReviewResources = 'review/nps' | 'review';
export const ReviewList = () => {
    const titles: Record<ReviewResources, string> = { review: 'מפגשים', 'review/nps': 'NPS' };
    const [resource, setResource] = useState<ReviewResources>('review');
    const controller = useListController({ resource });
    const refresh = useRefresh();

    return (
        <List
            pagination={<MyPagination />}
            title={titles[resource]}
            resource={resource}
            sort={{ field: 'createdAt', order: 'DESC' }}
            actions={
                <Stack direction='row' gap={2} py={1} width={'100%'}>
                    <Button
                        style={{ width: 150 }}
                        onClick={() => {
                            setResource('review');
                        }}
                        variant={resource === 'review' ? 'contained' : 'outlined'}>
                        {titles['review']}
                    </Button>
                    <Button
                        style={{ width: 150 }}
                        onClick={() => {
                            setResource('review/nps');
                        }}
                        variant={resource === 'review/nps' ? 'contained' : 'outlined'}>
                        {titles['review/nps']}
                    </Button>
                    <FilterButton style={{ width: 140, alignSelf: 'center' }} />
                    <CreateButton disabled={resource !== 'review'} />
                    <ExportButton />
                </Stack>
            }
            filters={[
                <ReferenceInput reference='company' source='company' label='חברה' perPage={999}>
                    <SelectInput optionText={'english_name'} fullWidth style={{ marginTop: 0 }} />
                </ReferenceInput>,
                <BooleanInput source='showToHR' label='מוצג ל-HR' />,
            ]}>
            {resource === 'review/nps' ? (
                <Datagrid data={controller.data}>
                    <DateField source='createdAt' locales={'he-IL'} />
                    <NumberField source='nps' textAlign='center' />

                    <ReferenceField reference='company' source='company'>
                        <TextField source='english_name' />
                    </ReferenceField>
                </Datagrid>
            ) : (
                <Datagrid data={controller.data}>
                    <DateField source='createdAt' locales={'he-IL'} />
                    <NumberField source='rating' />
                    <TextField source='text' />
                    <ReferenceField reference='company' source='company'>
                        <TextField source='english_name' />
                    </ReferenceField>
                    <TextField source='meeting_title' />
                    <BooleanField source='showToHR' label='מוצג ל-HR' />
                    <ArrayField source='meeting.service.host'>
                        <SingleFieldList>
                            <ChipField source='name' />
                        </SingleFieldList>
                    </ArrayField>
                    <FunctionField
                        render={(record: any) => (
                            <Button
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    await axios.put(`${constants.API_URL}/${resource}/${record._id}/showToHR`, {
                                        value: !record.showToHR,
                                    });
                                    controller.refetch();
                                    refresh();
                                }}>{`${record.showToHR ? 'הסתר' : 'הצג'}`}</Button>
                        )}
                    />
                </Datagrid>
            )}
        </List>
    );
};

export default ReviewList;
