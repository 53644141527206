import { Grid, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { BooleanInput, Create, NumberInput, SimpleForm, TextInput } from 'react-admin';

const SettingCreate = () => {
  const [type, setType] = useState('boolean');
  return (
    <Create>
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Select label='TYPE' onChange={(e) => setType(e.target.value)} value={type} fullWidth>
              <MenuItem value={'boolean'}>Boolean</MenuItem>
              <MenuItem value={'number'}>Number</MenuItem>
              <MenuItem value={'string'}>String</MenuItem>
            </Select>
          </Grid>
          <Box width='100%' />
          <Grid item sm={4}>
            <TextInput source='key' fullWidth />
          </Grid>
          <Grid item sm={4}>
            {type === 'boolean' ? (
              <BooleanInput source='value' fullWidth />
            ) : type === 'number' ? (
              <NumberInput source='value' fullWidth />
            ) : (
              <TextInput source='value' fullWidth />
            )}
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
export default SettingCreate;
