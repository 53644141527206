import { FormLabel as TextFieldDefault, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { AutocompleteArrayInput, Create, ImageField, ImageInput, NumberInput, ReferenceArrayInput, SelectInput, SimpleForm, TextInput, useNotify } from 'react-admin';
import { Personalization } from '../Meeting/MeetingCreate';
import { getLocalizedErrorMessage } from '../../utils/functions';

const ServiceCreate = () => {
	const [ignoreAge, setIgnoreAge] = useState(false);
	const transform = (data: any) => ({
		...data,
		age: ignoreAge ? { to: null, from: null } : data.age,
	});
	const [length, setLength] = useState(60);
	const notify = useNotify();
	return (
		<Create
			title={'יצירת שירות'}
			transform={transform}
			mutationOptions={{
				onError: (err: any) => {
					notify(getLocalizedErrorMessage(err), { type: 'error' });
				},
			}}>
			<SimpleForm dir='rtl' maxWidth={'100%'}>
				<Grid container spacing={2} alignItems='center'>
					<Grid item sm={5}>
						<TextInput source='title' label='כותרת' fullWidth />
					</Grid>
					<Grid item sm={5}>
						<TextInput source='english_title' dir='ltr' fullWidth label='כותרת באנגלית' />
					</Grid>
					<Box width='100%' />
					<Grid item sm={5}>
						<TextInput source='sub_title.he' dir='rtl' fullWidth label='כותרת משנה עברית' />
					</Grid>
					<Grid item sm={5}>
						<TextInput source='sub_title.en' dir='ltr' fullWidth label='כותרת משנה אנגלית' />
					</Grid>
					<Box width='100%' />

					{/* <Grid item sm={10}>
            <TextInput source='sub_title' dir='rtl' fullWidth label='כותרת משנה' />
          </Grid> */}

					<Grid item sm={3}>
						<TextInput source='audience_lng.he' dir='rtl' fullWidth label='קהל יעד' />
					</Grid>
					<Grid item sm={3}>
						<TextInput source='audience_lng.en' dir='ltr' fullWidth label='קהל יעד אנגלית' />
					</Grid>
					<Grid item sm={3}>
						<SelectInput
							choices={[
								{ id: 'he', name: 'עברית' },
								{ id: 'en', name: 'אנגלית' },
							]}
							source='language'
							dir='rtl'
							fullWidth
							label='שפת השירות'
						/>
					</Grid>
					<Grid item sm={4}>
						<ReferenceArrayInput source='host' reference='instructor/all' perPage={999} sort={{ field: 'name', order: 'ASC' }}>
							<AutocompleteArrayInput
								label='יועץ.ת'
								optionText='name'
								filterToQuery={(q) => ({ name: q })}
								matchSuggestion={(filter, suggestion) => suggestion.name.includes(filter)}
							/>
						</ReferenceArrayInput>
					</Grid>
					<Box width='100%' />
					{/* <Grid item sm={6}>
            <TextInput source='zoom' fullWidth />
          </Grid>
          <Grid item sm={2}>
            <TextInput source='zoom_password' fullWidth />
          </Grid> */}
					<Grid item sm={10}>
						<TextInput multiline source='description_lng.he' dir='rtl' fullWidth label='תיאור השירות' minRows={4} />
					</Grid>
					<Box width={'100%'} />
					<Grid item sm={10}>
						<TextInput multiline source='description_lng.en' fullWidth label='תיאור השירות אנגלית' minRows={4} dir='ltr' />
					</Grid>
					<Personalization setIgnoreAge={setIgnoreAge} ignoreAge={ignoreAge} />
					<Grid item sm={6} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', display: 'inline-flex' }}>
						<div style={{ alignItems: 'baseline', display: 'inline-flex' }}>
							<NumberInput
								required
								min={5}
								source='duration'
								label='משך השירות (בדקות)'
								step={5}
								value={length}
								onChange={(e) => {
									setLength(parseInt(e.target.value));
								}}
							/>
							<TextFieldDefault style={{ marginInlineStart: 10, alignSelf: 'center' }}>{`${Math.floor(length / 60)} שעות ו-${length % 60} דקות`}</TextFieldDefault>
						</div>
					</Grid>

					<Grid item sm={8}>
						<ImageInput source='image'>
							<ImageField source='src' />
						</ImageInput>
					</Grid>
					<Box width={'100%'} />
					<Grid item sm={3}>
						<SelectInput
							fullWidth
							source='anchorImage'
							style={{ marginTop: -1 }}
							choices={[
								{ id: 'top', name: 'top' },
								{ id: 'center', name: 'center' },
								{ id: 'bottom', name: 'bottom' },
							]}
						/>
					</Grid>
				</Grid>
			</SimpleForm>
		</Create>
	);
};
export default ServiceCreate;
