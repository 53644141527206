import { Typography, useMediaQuery } from '@mui/material';
import { BooleanField, CreateButton, Datagrid, List, SimpleList, TextField, useResourceContext } from 'react-admin';

const ListArticles = () => {
	const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
	const context = useResourceContext({ resource: 'article/all' });
	return (
		<List resource='article/all' hasCreate actions={<CreateButton resource='article' />}>
			{isSmall ? (
				<SimpleList
					resource={context}
					primaryText={(record) => (
						<Typography textAlign='right' fontWeight={'600'}>
							{`${record.title}`}
						</Typography>
					)}
					secondaryText={(record) => <Typography>{record.category.hebrew_name}</Typography>}
				/>
			) : (
				<Datagrid dir='rtl' rowClick='edit' resource='article'>
					<TextField source='title' textAlign='center' label='כותרת' />
					<TextField source='category.hebrew_name' label='קטגוריה' textAlign='center' />
					<BooleanField source='visible' textAlign='center' label='זמין' />
				</Datagrid>
			)}
		</List>
	);
};

export default ListArticles;
