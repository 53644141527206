import { Table, TableBody, TableCell, TableContainer, TableHead, Typography } from '@mui/material';
import { EditControllerResult } from 'react-admin';
import PrivateServiceRow from './PrivateServiceRow';

const PrivateServicesTable = ({
	data = [],
	current_instructor,
	refreshFunc,
	title,
	showCreate,
}: {
	data?: any[];
	current_instructor: EditControllerResult<any>;
	refreshFunc: (successMessage?: string) => Promise<void>;
	title?: string;
	showCreate?: boolean;
}) =>
	data.length > 0 || showCreate ? (
		<TableContainer style={{ overflowX: 'scroll' }}>
			<Typography variant='h5'>{title}</Typography>
			<Table size='small'>
				<TableHead>
					<TableCell style={{ minWidth: 300 }} align='right'>
						<Typography>{'כותרת'}</Typography>
					</TableCell>
					<TableCell style={{ minWidth: 300 }} align='right' width={250}>
						<Typography>{'תיאור'}</Typography>
					</TableCell>
					<TableCell width={50} align='right'>
						<Typography>{'מחיר'}</Typography>
					</TableCell>
					<TableCell width={70} align='right'>
						<Typography>{'מחיר לפני הנחה'}</Typography>
					</TableCell>
					<TableCell width={50} align='right'>
						<Typography>{'משך'}</Typography>
						<Typography fontSize={10}>{'בדקות'}</Typography>
					</TableCell>
					<TableCell width={50} align='right'>
						<Typography>{'ציבורי'}</Typography>
						<Typography fontSize={10}>{'לא מופיע למנויים'}</Typography>
					</TableCell>
				</TableHead>
				<TableBody>
					{data?.map((service) => (
						<PrivateServiceRow
							initService={service}
							isAdd={false}
							refreshFunc={(str) => {
								refreshFunc(str);
							}}
						/>
					))}
					{showCreate && (
						<PrivateServiceRow
							initService={{ instructor_id: current_instructor?.record?.id }}
							isAdd={true}
							refreshFunc={(str) => {
								refreshFunc(str);
							}}
						/>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	) : null;

export default PrivateServicesTable;
