import { Button, Dialog } from '@mui/material';
import axios from 'axios';

import { useReducer } from 'react';
import { useNotify } from 'react-admin';
import constants from '../constants';
import { downloadEncodedCSV } from '../utils/functions';
type Action = { type: 'UPDATE_MIN'; payload: number } | { type: 'UPDATE_MAX'; payload: number };
type State = { min: number; max: number };
const initialState: State = { min: 0, max: 0 };
const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'UPDATE_MAX':
            return { ...state, max: action.payload };
        case 'UPDATE_MIN':
            return { ...state, min: action.payload };
        default:
            return state;
    }
};
const CopyByDateOfBirthDialog = ({ open, onDismiss }: { open: boolean; onDismiss: () => void }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const notify = useNotify();
    return (
        <Dialog open={open} onClose={onDismiss}>
            <div className='w-[400px] min-h-[100px] p-4'>
                <h2>ייצוא מיילים לפי גילאי ילדים</h2>
                <div className='flex flex-row gap-4 mt-4 items-center'>
                    <span className='w-12'>מגיל</span>
                    <input
                        className='border w-12 p-1'
                        type='number'
                        min={0}
                        step={0.5}
                        value={state.min}
                        onChange={(e) => {
                            console.log(e.target.value);
                            dispatch({ type: 'UPDATE_MIN', payload: parseFloat(e.target.value) });
                        }}
                    />
                    <span className='w-12'>עד גיל</span>
                    <input
                        className='border w-12 p-1'
                        type='number'
                        min={0}
                        step={0.5}
                        value={state.max}
                        onChange={(e) => dispatch({ type: 'UPDATE_MAX', payload: parseFloat(e.target.value) })}
                    />
                    <Button
                        onClick={async () => {
                            try {
                                const response = await axios.post(`${constants.API_URL}/admin/emails-by-kids-dob`, state);
                                downloadEncodedCSV(response.data, ['email', '_email'], `members_${state.min}y_to_${state.max}y`);
                                onDismiss();
                            } catch (error) {
                                notify('error', { type: 'error' });
                            } finally {
                            }
                        }}>
                        העתק
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default CopyByDateOfBirthDialog;
