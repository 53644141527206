import {
	BooleanField,
	ChipField,
	Datagrid,
	FunctionField,
	ImageField,
	Link,
	List,
	ReferenceArrayField,
	ReferenceInput,
	SelectInput,
	SingleFieldList,
	TextField,
	TextInput,
} from 'react-admin';
import { FaEdit, FaPlusCircle } from 'react-icons/fa';
import MyPagination from '../MyPagination';
import { Typography } from '@mui/material';

const ServiceList = () => {
	const filters = [
		<TextInput source='title' />,
		<ReferenceInput
			label='Instructor'
			source='host._id'
			reference='instructor/all'
			perPage={1000}
			sort={{ field: 'name', order: 'ASC' }}>
			<SelectInput optionText={'name'} />
		</ReferenceInput>,
		<ReferenceInput label='Tag' source='tags' reference='tag/all' perPage={1000}>
			<SelectInput optionText={'name.he'} />
		</ReferenceInput>,
	];
	return (
		<List filters={filters} pagination={<MyPagination />}>
			<Datagrid id='_id'>
				<TextField source='title' textAlign='right' label='כותרת' />
				<ImageField source='image' />
				<ReferenceArrayField reference='instructor' source='host' label='יועצים/ות'>
					<SingleFieldList>
						<ChipField source='name' />
					</SingleFieldList>
				</ReferenceArrayField>

				<ReferenceArrayField reference='tag/all' source='tags' label='תגיות'>
					<SingleFieldList>
						<ChipField source='name.he' />
					</SingleFieldList>
				</ReferenceArrayField>
				<BooleanField label='הריון ולידה' source='for_pregnant' />
				<FunctionField
					label='גילאים'
					render={(record: any) =>
						(record?.age?.from || record?.age?.to) && (
							<Typography fontSize={12} width={50}>{`${record?.age?.from}-${record?.age?.to}`}</Typography>
						)
					}
				/>
				<FunctionField
					label='צור מפגש'
					textAlign='center'
					render={(record: { id: any }) => (
						<Link to={`/meeting/create?service=${record.id}`}>
							<FaPlusCircle />
						</Link>
					)}
				/>
				<FunctionField
					textAlign='center'
					label='עריכה'
					render={(record: { id: any }) => (
						<Link to={`/service/${record.id}`}>
							<FaEdit />
						</Link>
					)}
				/>
			</Datagrid>
		</List>
	);
};
export default ServiceList;
