import { Button, Checkbox, Input, MenuItem, Select, Stack, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';

import constants from '../../constants';

const PrivateServiceRow = ({
  initService,
  isAdd,
  refreshFunc,
}: {
  initService: any;
  isAdd?: boolean;
  refreshFunc: (str?: string) => void;
}) => {
  const [service, setService] = useState(initService);
  return (
    <TableRow hover>
      <TableCell align='right'>
        <Stack spacing={2}>
          <Input
            fullWidth
            placeholder='עברית'
            multiline
            onChange={(e) => setService((prev: any) => ({ ...prev, title: { ...prev.title, he: e.target.value } }))}
            value={service.title?.he}
          />

          <Input
            fullWidth
            placeholder='אנגלית'
            multiline
            onChange={(e) => setService((prev: any) => ({ ...prev, title: { ...prev.title, en: e.target.value } }))}
            value={service.title?.en}
          />
        </Stack>
      </TableCell>
      <TableCell align='right'>
        <Stack spacing={2}>
          <Input
            placeholder='עברית'
            fullWidth
            multiline
            onChange={(e) =>
              setService((prev: any) => ({ ...prev, description: { ...prev.description, he: e.target.value } }))
            }
            value={service.description?.he}
          />
          <Input
            placeholder='אנגלית'
            fullWidth
            multiline
            onChange={(e) =>
              setService((prev: any) => ({ ...prev, description: { ...prev.description, en: e.target.value } }))
            }
            value={service.description?.en}
          />
        </Stack>
      </TableCell>
      <TableCell align='right'>
        <Input
          fullWidth
          onChange={(e) => setService((prev: any) => ({ ...prev, price: e.target.value }))}
          value={service.price}
        />
      </TableCell>
      <TableCell align='right'>
        <Input
          fullWidth
          onChange={(e) => setService((prev: any) => ({ ...prev, price_before_discount: e.target.value }))}
          value={service.price_before_discount}
        />
      </TableCell>
      <TableCell align='right'>
        <Input
          fullWidth
          onChange={(e) => setService((prev: any) => ({ ...prev, duration: e.target.value }))}
          value={service.duration}
        />
      </TableCell>
      <TableCell align='right'>
        <Checkbox
          onChange={(e) => setService((prev: any) => ({ ...prev, public: e.target.checked }))}
          checked={service.public}
        />
      </TableCell>
      {/* <TableCell align='right'>
        <Checkbox
          onChange={(e) => setService((prev: any) => ({ ...prev, emergency: e.target.checked }))}
          checked={service.emergency}
        />
      </TableCell> */}
      <TableCell align='right'>
        <Select onChange={(e) => setService((prev: any) => ({ ...prev, type: e.target.value }))} value={service.type}>
          <MenuItem value={'1TO1'}>אחד על אחד</MenuItem>
          <MenuItem value={'HELPING_HAND'}>עזרה ראשונה</MenuItem>
          <MenuItem value={'SAFE_SPACE'}>מרחב בטוח</MenuItem>
          <MenuItem value={'DOCTORS'}>רופאים</MenuItem>
        </Select>
      </TableCell>
      <TableCell align='right'>
        <Stack spacing={1}>
          <Button
            onClick={async () => {
              if (isAdd) {
                await axios.post(`${constants.API_URL}/instructor/private_services/`, service);
                setService(initService);
              } else {
                await axios.put(`${constants.API_URL}/instructor/private_services/`, { id: service._id, ...service });
              }
              if (refreshFunc) refreshFunc();
            }}
          >
            {isAdd ? 'הוספה' : 'עדכון'}
          </Button>
          {!isAdd ? (
            <Button
              variant='outlined'
              color='error'
              onClick={async () => {
                await axios.delete(`${constants.API_URL}/instructor/private_services/${service._id}`);
                if (refreshFunc) refreshFunc('נמחק');
              }}
            >
              מחיקה
            </Button>
          ) : null}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default PrivateServiceRow;
