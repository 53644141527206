import { Box } from '@mui/system';
import {
  BooleanField,
  ChipField,
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  ReferenceField,
  TextField,
  useResourceContext,
} from 'react-admin';

const CategoryList = () => {
  const context = useResourceContext({ resource: 'instructor-category' });
  return (
    <List
      resource='instructor-category/all'
      title={'קטגוריות'}
      actions={
        <Box>
          <CreateButton resource='instructor-category' />
          <ExportButton />
        </Box>
      }
    >
      <Datagrid rowClick='edit' resource={context} style={{ textAlign: 'right' }}>
        <TextField source='hebrew_name' label='עברית' textAlign='right' dir='rtl' width={100} />
        <TextField source='english_name' label='אנגלית' textAlign='right' dir='rtl' width={100} />
        <ReferenceField source='parent' reference='instructor-category/all'>
          <ChipField source='hebrew_name' />
        </ReferenceField>
        <TextField source='__t' />
        <BooleanField source='show' />
        <TextField source='order' label='סדר' textAlign='right' dir='rtl' width={40} />
      </Datagrid>
    </List>
  );
};

export default CategoryList;
