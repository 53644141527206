import { Admin, CustomRoutes, defaultTheme, Layout } from 'react-admin';
import './App.css';
import authProvider from './providers/auth.provider';

import { ThemeOptions } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Dashboard } from './Dashboard/Dashboard';
import { MyMenu } from './Menu';
import myDataProvider from './providers/myDataProvider';
import Resources from './Resources';
function App() {
    const myTheme: ThemeOptions = {
        ...defaultTheme,

        direction: 'rtl',
        components: {
            MuiTableCell: { defaultProps: { style: { textAlign: 'right', direction: 'rtl' } } },
            MuiSelect: { defaultProps: { dir: 'rtl', style: { paddingRight: 0 } }, styleOverrides: { outlined: { textAlign: 'right' } } },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        // Default styles for the unfocused state
                        textAlign: 'right',
                        transform: 'translate(-10px, 10px)',
                        right: 0,
                        paddingInlineStart: 0,
                        left: 'auto',
                        transformOrigin: 'top right',
                        transition: 'all 0.3s ease', // Smooth transition for styling changes
                        '&.MuiInputLabel-shrink': {
                            // Styles when focused or input has a value
                            textAlign: 'right', // Example: reset to default for focused state
                            transform: 'translate(-14px, -5.5px) scale(0.75)', // Focused transformation
                        },
                    },
                },
            },

            MuiSvgIcon: {
                defaultProps: { direction: 'ltr', style: { right: 'auto', left: 6 } },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        textAlign: 'right',
                    },
                },
            },
        },
        typography: {
            // Use the system font instead of the default Roboto font.
            fontFamily: ['Open Sans', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
        },
    };
    useEffect(() => {
        axios.defaults.headers.common['x-client'] = 'admin-web';
    }, []);

    return (
        <div className='App'>
            <Admin
                authProvider={authProvider}
                dataProvider={myDataProvider}
                requireAuth
                theme={myTheme}
                layout={(props) => <Layout {...props} menu={MyMenu} />}
                dashboard={Dashboard}>
                {(permissions) => (
                    <>
                        {Resources(permissions)}
                        <CustomRoutes>
                            <Route path='/stats' />
                        </CustomRoutes>
                    </>
                )}
            </Admin>
        </div>
    );
}

export default App;
