import { Grid } from '@mui/material';
import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const LectureCategoryCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item sm={12} lg={6}>
            <TextInput source='name.he' fullWidth label='שם (עברית)' dir='rtl' />
          </Grid>
          <Grid item sm={12} lg={6}>
            <TextInput source='name.en' fullWidth label='שם (אנגלית)' dir='rtl' />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default LectureCategoryCreate;
