import { Checkbox, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useCallback, useState } from 'react';
import {
    BooleanInput,
    Create,
    DateTimeInput,
    FormDataConsumer,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceArrayInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import AsideChangeLog from '../AsideChangeLog';
import SelectMeetingService from '../SelectMeetingService';
export const Personalization = (props: { prefix?: string; ignoreAge: boolean; setIgnoreAge: (a: boolean) => void }) => {
    return (
        <Grid container spacing={2} paddingTop={2} sm={12} alignItems='center'>
            <Grid item sm={2}>
                <NumberInput disabled={props.ignoreAge} source={`${props.prefix ? `${props.prefix}.` : ''}age.from`} fullWidth label='מגיל' />
            </Grid>
            <Grid item sm={2}>
                <NumberInput disabled={props.ignoreAge} source={`${props.prefix ? `${props.prefix}.` : ''}age.to`} fullWidth label='עד גיל' />
            </Grid>
            <Grid item sm={2}>
                <Typography>התעלם מגיל</Typography>
                <Checkbox value={props.ignoreAge} onChange={(e) => props.setIgnoreAge(e.target.checked)} />
            </Grid>
            <Grid item sm={3}>
                <ReferenceArrayInput reference='tag/all' resource={'tag/all'} perPage={100} source={`${props.prefix ? `${props.prefix}.` : ''}tags`} label='tags'>
                    <SelectArrayInput optionText={'name.he'} optionValue={'_id'} />
                </ReferenceArrayInput>
            </Grid>
            <Grid item sm={3}>
                <BooleanInput source={`${props.prefix ? `${props.prefix}.` : ''}for_pregnant`} label='הריון' fullWidth />
            </Grid>
            <Box width='100%' />
            <Grid item sm={6}>
                <ReferenceArrayInput reference='company' source={`${props.prefix ? `${props.prefix}.` : ''}hide_for.companies`} label='הסתר מחברות' fullWidth perPage={999}>
                    <SelectArrayInput optionText='english_name' optionValue='_id' fullWidth />
                </ReferenceArrayInput>
            </Grid>
            <Grid item sm={4}>
                <SelectArrayInput
                    source={`${props.prefix ? `${props.prefix}.` : ''}hide_for.types`}
                    fullWidth
                    choices={[
                        { name: 'newborn_3m', id: 'newborn_3m' },
                        { name: 'newborn_6m', id: 'newborn_6m' },
                        { name: 'trial', id: 'trial' },
                    ]}
                />
            </Grid>

            <Box width='100%' />
        </Grid>
    );
};
const MeetingCreate = (props: any) => {
    const [ignoreAge, setIgnoreAge] = useState(false);

    const transform = useCallback(
        (data: any) => ({
            ...data,
            meeting_data: {
                ...data.meeting_data,
                age: ignoreAge ? { to: null, from: null } : data.meeting_data.age,
            },
        }),
        [ignoreAge]
    );

    return (
        <Create transform={transform} aside={<AsideChangeLog items={[]} />}>
            <SimpleForm width={'100%'}>
                <Grid container spacing={2} paddingTop={2}>
                    {/* <Grid item xs={12}> */}
                    <Grid item xs={12}>
                        <SelectMeetingService source='meeting_data.service' />
                    </Grid>
                    <FormDataConsumer>
                        {({ formData }) => {
                            return formData.meeting_data?.service ? (
                                <>
                                    <Box width={'100%'} paddingTop={3} paddingBottom={3}>
                                        <Typography variant='h5' textAlign={'right'}>
                                            ניתן להשאיר שדות ריקים על מנת להשתמש בנתוני השירות
                                        </Typography>
                                    </Box>
                                    <Grid item xs={5}>
                                        <TextInput source='meeting_data.title' label='כותרת' fullWidth style={{ textAlign: 'right' }} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextInput source='meeting_data.english_title' label='כותרת באנגלית' fullWidth dir='ltr' />
                                    </Grid>

                                    <Grid item xs={5}>
                                        <TextInput source='meeting_data.sub_title.he' fullWidth />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextInput source='meeting_data.sub_title.en' fullWidth />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextInput source='meeting_data.description_lng.he' multiline fullWidth label='תיאור בעברית' />
                                    </Grid>
                                    <Box width={'100%'} />
                                    <Grid item xs={5}>
                                        <TextInput source='meeting_data.description_lng.en' multiline fullWidth label='תיאור באנגלית' dir='ltr' />
                                    </Grid>
                                    <Box width={'100%'} />
                                    <Grid item md={2}>
                                        <NumberInput source='meeting_data.price' label='מחיר' fullWidth defaultValue={45} />
                                    </Grid>
                                    <Grid item md={2}>
                                        <NumberInput source='meeting_data.price_for_members' label='מחיר למנויי גרופהאג' fullWidth defaultValue={0} />
                                    </Grid>
                                    <Grid item md={2}>
                                        <BooleanInput source='meeting_data.registration_limit_on' label={'הגבלת מספר משתתפים'} fullWidth />
                                    </Grid>
                                    <Grid item md={2}>
                                        <FormDataConsumer<{ meeting_data: { registration_limit_on: boolean } }>>
                                            {({ formData, ...rest }) =>
                                                formData.meeting_data.registration_limit_on && (
                                                    <NumberInput source='meeting_data.max_register' fullWidth label='הגבלת מספר משתתפים' />
                                                )
                                            }
                                        </FormDataConsumer>
                                    </Grid>

                                    <Box width={'100%'} />
                                    <Grid item sm={4}>
                                        <DateTimeInput source='meeting_data.time' required label='מועד המפגש' fullWidth />
                                    </Grid>
                                    <Personalization prefix='meeting_data' setIgnoreAge={setIgnoreAge} ignoreAge={ignoreAge} />

                                    <Grid item sm={4}>
                                        <SelectInput
                                            fullWidth
                                            sx={{ marginTop: 0 }}
                                            required
                                            label='אזור זמן'
                                            source='timezone'
                                            defaultValue={'Israel'}
                                            choices={[
                                                { id: 'Israel', name: 'Israel' },
                                                { id: 'America/New_York', name: 'New York' },
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <BooleanInput source='meeting_data.should_record' label='מפגש מוקלט' fullWidth />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <BooleanInput source='addDefaultNotifications' defaultValue={true} label='הגדרת תזכורות ברירת מחדל' />
                                    </Grid>
                                </>
                            ) : null;
                        }}
                    </FormDataConsumer>
                    <Box width='100%' />
                    <Typography variant='h5' display={'block'}>
                        מנהלה
                    </Typography>

                    <Box width='100%' />
                    <Grid item xs={12} lg={3}>
                        <ReferenceArrayInput reference={`vod/categories`} source={`meeting_data.extra.vod_category`}>
                            <SelectArrayInput optionText={'hebrew_name'} label='להעלות הקלטה לקטגוריה' fullWidth />
                        </ReferenceArrayInput>
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <BooleanInput source={`meeting_data.extra.zoom_test_required`} label='צריך לעלות לטסט' />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <BooleanInput source={`meeting_data.extra.zoom_join_required`} label='צריך לעלות למפגש' />
                    </Grid>
                    <Grid item xs={7}>
                        <TextInput source={`meeting_data.extra.text`} label='הערות' fullWidth multiline />
                    </Grid>
                </Grid>
                <Grid container>
                    <ImageInput source='picture'>
                        <ImageField source='src' />
                    </ImageInput>
                </Grid>
                {/* </Grid> */}
            </SimpleForm>
        </Create>
    );
};
export default MeetingCreate;
