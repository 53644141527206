import { Grid, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { RichTextInput } from 'ra-input-rich-text';
import { useState } from 'react';
import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';

const SettingEdit = () => {
  const [type, setType] = useState('boolean');
  return (
    <Edit>
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Select label='TYPE' onChange={(e) => setType(e.target.value)} value={type} fullWidth>
              <MenuItem value={'boolean'}>Boolean</MenuItem>
              <MenuItem value={'number'}>Number</MenuItem>
              <MenuItem value={'string'}>String</MenuItem>
              <MenuItem value={'richText'}>richText</MenuItem>
            </Select>
          </Grid>
          <Box width='100%' />
          <Grid item sm={4}>
            <TextInput source='key' fullWidth />
          </Grid>
          <Grid item sm={8}>
            {type === 'boolean' ? (
              <BooleanInput source='value' fullWidth />
            ) : type === 'number' ? (
              <NumberInput source='value' fullWidth />
            ) : type === 'richText' ? (
              <RichTextInput source='value' />
            ) : (
              <TextInput dir='ltr' source='value' fullWidth multiline />
            )}
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
export default SettingEdit;
