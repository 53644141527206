import axios from 'axios';
import Dropzone, { IDropzoneProps, ILayoutProps, defaultClassNames } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import constants from '../constants';

const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
	return (
		<div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
			{previews}
			{files.length < maxFiles && <div {...dropzoneProps}>{input}</div>}
			{files.length > 0 && submitButton}
		</div>
	);
};

const ImageDropZone = (props: { title: string; resource: string; id: string; onUpdate: () => void; field: string }) => {
	const handleSubmit: IDropzoneProps['onSubmit'] = async (files, allFiles) => {
		const body = new FormData();
		body.append(props.field, files[0].file);
		await axios.put(`${constants.API_URL}${props.resource}/${props.id}`, body);
		allFiles.forEach((f) => f.remove());
		if (props.onUpdate) setTimeout(props.onUpdate, 2000);
		return;
	};

	return (
		<Dropzone
			multiple={false}
			maxFiles={1}
			LayoutComponent={Layout}
			onSubmit={handleSubmit}
			classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
			inputContent={`העלאת ${props.title}`}
		/>
	);
};

export default ImageDropZone;
