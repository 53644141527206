import { Box, Button } from '@mui/material';
import axios from 'axios';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  useListController,
} from 'react-admin';
import { BiGift } from 'react-icons/bi';
import constants from '../../constants';

const InvitationsList = () => {
  const resource = useListController({ resource: 'invitation' });
  return (
    <List
      actions={
        <Box p={2}>
          <CreateButton
            size='medium'
            style={{ backgroundColor: 'pink', direction: 'ltr', color: 'black' }}
            variant='contained'
            alignIcon='left'
            icon={<BiGift />}
            label='שליחת מנוי לידה'
          />
        </Box>
      }
    >
      <Datagrid>
        <DateField source='createdAt' label='תאריך' textAlign='right' />
        <FunctionField
          label='שולח'
          textAlign='right'
          render={(record: any) => `${record.sender?.first_name} ${record.sender?.last_name || ''}`}
        />
        <FunctionField
          label='מקבל המתנה'
          textAlign='right'
          render={(record: any) => `${record.receiver?.first_name} ${record.receiver?.last_name || ''}`}
        />
        <BooleanField source='redeemed' label='מומש' textAlign='right' />
        <FunctionField
          render={(record: any) =>
            !record.redeemed && (
              <Button
                onClick={async () => {
                  await axios.get(`${constants.API_URL}/invitation/${record._id}/remind`);
                  resource.refetch();
                }}
              >
                {`שליחת תזכורת (${record.reminder_count})`}
              </Button>
            )
          }
        />
        <TextField source='text' label='טקסט' textAlign='right' />
        <TextField source='type' label='סוג' textAlign='right' />
      </Datagrid>
    </List>
  );
};

export default InvitationsList;
